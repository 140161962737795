import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import API from '../../services/API';
import { Fab, IconButton } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import PopupDB from './PopupDB';
import {useSnackbar} from 'notistack';




export default function Clients(){
    const [clients, setClients] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false)
    const [dataChange, setDataChange] = useState(true)
    const [actionPopup, setActionPopup] = useState("Créer")
    const [modifyingData, setModifyingData] = useState({})

    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        {field: 'actions', headerName: 'Actions', sortable: false,
            renderCell: (params) => {
              const onClickModify = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                handleModify(params.row)
              };
              const onClickDelete = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                let conf = window.confirm("Etes-vous sûr de vouloir supprimer cette production ? Pas de retour en arrière ...")
                if(conf){
                    handleDelete(params.row)
                }
              };
        
              return(
                  <>
                      <IconButton color="primary" component="span" onClick={onClickModify}><Edit /></IconButton>
                      <IconButton color="primary" component="span" onClick={onClickDelete}><Delete /></IconButton>
                  </>
              )
            },
        },
        { field: 'id'},
        { field: 'nom', headerName: 'Nom'},
        { field: 'prenom', headerName: 'Prénom'},
        { field: 'tel1', headerName: 'Téléphone 1'},
        { field: 'tel2', headerName: 'Téléphone 2'},
        { field: 'adresse', headerName: 'Adresse'},
        { field: 'adresse2', headerName: 'Adresse(2)'},
        { field: 'code_postal', headerName: 'Code postal'},
        { field: 'ville', headerName: 'Ville'},
        { field: 'email', headerName: 'Email'},
        { field: 'type', headerName: 'Type'},
        { field: 'fidelite', headerName: 'fidelite'},
        { field: 'taux_prix', headerName: 'Taux prix'}
    ];

    useEffect(()=>{
        if(!dataChange) return
        API('GET','Client')
        .then(res=>setClients(res.json))
        setDataChange(false)
    },[dataChange])

    const handleAdd = ()=>{
        setActionPopup("Créer")
        setModifyingData({})
        setPopupOpen(true)
    }

    const handleModify = (data)=>{
        setActionPopup("Modifier")
        setModifyingData(data)
        setPopupOpen(true)
    }
    const handleDelete = (data)=>{
        API("DELETE","Client",{id:data.id})
        .then(res=>{
            if(!res.ok){//ERREUR
                enqueueSnackbar("Erreur lors de la suppression : "+res.json.message,{variant: "error"})
            }else{
                enqueueSnackbar("Suppression réussie",{variant: "success"})
            }
            setDataChange(true)
        })
    }

    return (
        <>
            <DataGrid
                rows={clients}
                columns={columns}
                disableSelectionOnClick 
                autoHeight
            />
            <Fab color="primary" aria-label="Add" sx={{position: "fixed", bottom: "10px", right: "10px"}} onClick={handleAdd}>
                <Add />
            </Fab>
            <PopupDB action={actionPopup} data={modifyingData} extraData={[]}  table="client" open={popupOpen} setOpen={setPopupOpen} setDataChange={setDataChange}/>
        </>
    )
}