import { useEffect, useState, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import API from '../../services/API';
import { Fab, IconButton } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import PopupDB from './PopupDB';
import {useSnackbar} from 'notistack';
import { UserContext } from "../../services/UserContext"




export default function ProduitsPrevisionnels(){
    const [produitsPrevisionnels, setProduitsPrevisionnels] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false)
    const [dataChange, setDataChange] = useState(true)
    const [actionPopup, setActionPopup] = useState("Créer")
    const [modifyingData, setModifyingData] = useState({})
    const [userContext, setUserContext] = useContext(UserContext)

    const { enqueueSnackbar } = useSnackbar();

    let columns = [
        { field: 'id'},
        { field: 'nom_periode', headerName: 'Période'},
        { field: 'Produit', valueGetter: (params) => {
            return `(#${params.row.Produit_id}) ${params.row.Produit_nom}`;
        }},
        { field: 'quantite_lundi', headerName: 'Quantité Lundi'},
        { field: 'quantite_mardi', headerName: 'Quantité Mardi'},
        { field: 'quantite_mercredi', headerName: 'Quantité Mercredi'},
        { field: 'quantite_jeudi', headerName: 'Quantité Jeudi'},
        { field: 'quantite_vendredi', headerName: 'Quantité Vendredi'},
        { field: 'quantite_samedi', headerName: 'Quantité Samedi'},
        { field: 'quantite_dimanche', headerName: 'Quantité Dimanche'}
    ];
    if(userContext.role!=="standard"){
        columns = [
            {field: 'actions', headerName: 'Actions', sortable: false,
                renderCell: (params) => {
                  const onClickModify = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    handleModify(params.row)
                  };
                  const onClickDelete = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    let conf = window.confirm("Etes-vous sûr de vouloir supprimer cette prévision ? Pas de retour en arrière ...")
                    if(conf){
                        handleDelete(params.row)
                    }
                  };
            
                  return(
                      <>
                          <IconButton color="primary" component="span" onClick={onClickModify}><Edit /></IconButton>
                          <IconButton color="primary" component="span" onClick={onClickDelete}><Delete /></IconButton>
                      </>
                  )
                },
            },
            ...columns
        ]
    }

    useEffect(()=>{
        if(!dataChange) return
        API('GET','Produit_Previsionnel')
        .then(res=>setProduitsPrevisionnels(res.json))
        setDataChange(false)
    },[dataChange])

    const handleAdd = ()=>{
        setActionPopup("Créer")
        setModifyingData({})
        setPopupOpen(true)
    }

    const handleModify = (data)=>{
        let tmp = {...data}
        delete tmp.Produit_nom
        setActionPopup("Modifier")
        setModifyingData(tmp)
        setPopupOpen(true)
    }
    const handleDelete = (data)=>{
        API("DELETE","Produit_Previsionnel",{id:data.id})
        .then(res=>{
            if(!res.ok){//ERREUR
                enqueueSnackbar("Erreur lors de la suppression",{variant: "error"})
            }else{
                enqueueSnackbar("Suppression réussie",{variant: "success"})
            }
            setDataChange(true)
        })
    }

    return (
        <>
            <DataGrid
                rows={produitsPrevisionnels}
                columns={columns}
                disableSelectionOnClick 
                autoHeight
            />
            {userContext.role!=="standard" && 
                <Fab color="primary" aria-label="Add" sx={{position: "fixed", bottom: "10px", right: "10px"}} onClick={handleAdd}>
                    <Add />
                </Fab>
            }
            <PopupDB action={actionPopup} data={modifyingData} extraData={[]}  table="Produit_Previsionnel" open={popupOpen} setOpen={setPopupOpen} setDataChange={setDataChange}/>
        </>
    )
}