import { useEffect, useState } from "react";
import API from "../services/API";
import { Box, Typography } from "@mui/material";
import Dropdown from "../components/Dropdown";
import CheckboxItem from "../components/CheckboxItem";
import dayjs from "dayjs";
import Number from "../components/Number";
import { LoadingButton } from "@mui/lab";




export default function ReceptionFrn(){
    const [fournisseurs, setFournisseurs] = useState([]);
    const [selectedFournisseur, setSelectedFournisseur] = useState(undefined);

    const [lignesCommande, setLignesCommande] = useState([]);

    const [quantitesAValider, setQuantitesAValider] = useState({});
    const [validationEnCours, setValidationEnCours] = useState(false);

    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        API("GET", "Fournisseur")
        .then(res=>setFournisseurs(res.json))
    }, []);


    useEffect(() => {
        if(selectedFournisseur===undefined && !refresh) return
        setRefresh(false)

        //setQuantitesAValider({})

        API("GET", "Ligne_Commande_Fournisseur",{},{Fournisseur_id:fournisseurs.find(f=>f.nom===selectedFournisseur)?.id, resteALivrer:true, avecProduit_nom:true, orderBy: "date_reception_estimee, Produit_nom"})
        .then(res=>{
            setLignesCommande(res.json)
            setQuantitesAValider(currVal =>{
                let tmp = {...currVal}
                Object.keys(tmp).forEach(ligne_id=>{
                    if(res.json.find(lc=>lc.id===ligne_id) === undefined){
                        delete tmp[ligne_id]
                    }
                })
                return tmp
            })
        })
    }, [selectedFournisseur, refresh]);


    const handleChange = (ligne_id, type, quantite)=>{
        setQuantitesAValider(currVal=>{
            let tmp = {...currVal}
            if(!tmp.hasOwnProperty(ligne_id)){
                tmp[ligne_id] = {id:ligne_id}
            }
            tmp[ligne_id][type] = quantite||0
            return tmp
        })
    }

    const handleReceptionner = ()=>{
        setValidationEnCours(true)
        let toSend = Object.values(quantitesAValider).filter(q=>q.quantite_livree+q.quantite_refusee>0)

        API("PATCH","ligne_commande_fournisseur",{values:toSend, columns:["quantite_livree","quantite_refusee"]})
        .then(res=>{
            setRefresh(true)
            setValidationEnCours(false)
        })
    }

    /*useEffect(() => {
        console.log(quantitesAValider)
    }, [quantitesAValider]);*/
    

    return(
        <>
            <Typography variant="h5" sx={{textAlign:'center'}}>Réception fournisseur</Typography>

            <Box>
                <Dropdown name="Fournisseur" options={fournisseurs.map(f=>f.nom)} onChange={setSelectedFournisseur} addFirstDisabledOption={true} style={{width:250, mr:6}} otherProps={{size:"small"}}/>
            </Box>

            <Box sx={{mt:3, borderBottom:"1px solid black"}}>
                <Box sx={{display:"inline-block", width:"21%"}}>Produit</Box>
                <Box sx={{display:"inline-block", width:"17%", textAlign:"center"}}>Date commande</Box>
                <Box sx={{display:"inline-block", width:"17%", textAlign:"center"}}>Date réception estimée</Box>
                <Box sx={{display:"inline-block", width:"15%", textAlign:"center"}}>Restante</Box>
                <Box sx={{display:"inline-block", width:"15%", textAlign:"center"}}>Livrée</Box>
                <Box sx={{display:"inline-block", width:"15%", textAlign:"center"}}>Refusée</Box>
            </Box>

            <Box>
                {lignesCommande?.map(lc=>(
                <Box key={lc.id} sx={{my:2}}>
                    <Box sx={{display:"inline-block", width:"21%"}}>{lc.Produit_nom}</Box>
                    <Box sx={{display:"inline-block", width:"17%", textAlign:"center"}}>{lc.date_commande}</Box>
                    <Box sx={{display:"inline-block", width:"17%", textAlign:"center"}}>{lc.date_reception_estimee}</Box>
                    <Box sx={{display:"inline-block", width:"15%", textAlign:"center"}}>{+lc.quantite_restante +" / " + +lc.quantite_commandee}</Box>
                    <Box sx={{display:"inline-block", width:"15%", textAlign:"center"}}>
                        <Number onChange={(v)=>handleChange(lc.id,"quantite_livree",+lc.quantite_livree+v)} defaultValue={+lc.quantite_restante} refreshDefaultValue={refresh} maximum={+lc.quantite_commandee-quantitesAValider[lc.id]?.quantite_refusee} allowNegative={false} inputProps={{size:"small"}} style={{width:70,verticalAlign:"middle"}} />
                    </Box>
                    <Box sx={{display:"inline-block", width:"15%", textAlign:"center"}}>
                        <Number onChange={(v)=>handleChange(lc.id,"quantite_refusee",+lc.quantite_refusee+v)} defaultValue={0} refreshDefaultValue={refresh} maximum={+lc.quantite_commandee-quantitesAValider[lc.id]?.quantite_livree||0} allowNegative={false} inputProps={{size:"small"}} style={{width:70,verticalAlign:"middle"}} />
                    </Box>
                </Box>
                ))}
            </Box>

            <Box>
                <Box sx={{display:"inline-block", width:"70%"}}></Box>
                <Box sx={{display:"inline-block", width:"30%", textAlign:"center"}}>
                    <LoadingButton onClick={handleReceptionner} loading={validationEnCours} disabled={Object.values(quantitesAValider).reduce((acc,q)=>acc+q.quantite_livree+q.quantite_refusee,0)==0} variant="contained">Valider</LoadingButton>
                </Box>
            </Box>
        </>
    )

}