import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";


export default function DatePicker2({name, defaultValue, required, InputProps, onChange, disabled, style, otherProps={}}){
    const [value, setValue] = useState(defaultValue)
    const [error, setError] = useState(false)
    const [otherPropsLocal, setOtherPropsLocal] = useState({});

    useEffect(()=>{
        if(defaultValue==undefined) defaultValue=""
        setValue(defaultValue)
    },[defaultValue])


    useEffect(() => {
        let temp = otherProps
        if(otherProps?.minDate === "dayjs()"){
            temp.minDate = dayjs()
        }
        setOtherPropsLocal(temp)
    }, [otherProps]);

    const handleChange=(v, context)=>{
        setValue(v)
        if(required && v ===""){
            setError(true)
            onChange(undefined)
        }else{
            setError(false)
            onChange(v)
        }
    } 
    return(
        <DatePicker
            label={name + (required?" *":"")}
            onChange={handleChange} 
            value={value} 
            //minDate={dayjs()} 
            format="DD-MM-YYYY" 
            sx={{"& > .MuiInputBase-root > .MuiInputBase-input":{py:1}}}
            style={style} 
            disabled={disabled}
            {...otherPropsLocal}
        />
    )
}