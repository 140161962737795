import { useEffect, useState, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import API from '../../services/API';
import { Fab, IconButton } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import PopupDB from './PopupDB';
import {useSnackbar} from 'notistack';
import { UserContext } from "../../services/UserContext"




export default function Locaux(){
    const [locaux, setLocaux] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false)
    const [dataChange, setDataChange] = useState(true)
    const [actionPopup, setActionPopup] = useState("Créer")
    const [modifyingData, setModifyingData] = useState({})
    const [userContext, setUserContext] = useContext(UserContext)

    const { enqueueSnackbar } = useSnackbar();

    let columns = [
        { field: 'nom', headerName: 'Nom', width:300}
    ];
    if(userContext.role!=="standard"){
        columns = [
            {field: 'actions', headerName: 'Actions', sortable: false,
                renderCell: (params) => {
                  const onClickModify = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    handleModify(params.row)
                  };
                  const onClickDelete = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    let conf = window.confirm("Etes-vous sûr de vouloir supprimer ce local ? Pas de retour en arrière ...")
                    if(conf){
                        handleDelete(params.row)
                    }
                  };
            
                  return(
                      <>
                          {/*<IconButton color="primary" component="span" onClick={onClickModify}><Edit /></IconButton>*/}
                          <IconButton color="primary" component="span" onClick={onClickDelete}><Delete /></IconButton>
                      </>
                  )
                },
            },
            ...columns
        ]
    }

    useEffect(()=>{
        if(!dataChange) return
        API('GET','Local')
        .then(res=>setLocaux(res.json))
        setDataChange(false)
    },[dataChange])

    const handleAdd = ()=>{
        setActionPopup("Créer")
        setModifyingData({})
        setPopupOpen(true)
    }

    const handleModify = (data)=>{
        setActionPopup("Modifier")
        setModifyingData(data)
        setPopupOpen(true)
    }
    const handleDelete = (data)=>{
        API("DELETE","Local",{id:data.id})
        .then(res=>{
            if(!res.ok){//ERREUR
                enqueueSnackbar("Erreur lors de la suppression",{variant: "error"})
            }else{
                enqueueSnackbar("Suppression réussie",{variant: "success"})
            }
            setDataChange(true)
        })
    }

    return (
        <>
            <DataGrid
                rows={locaux}
                columns={columns}
                disableSelectionOnClick 
                autoHeight
                getRowId={(row) => row.nom}
            />
            {userContext.role!=="standard" && 
                <Fab color="primary" aria-label="Add" sx={{position: "fixed", bottom: "10px", right: "10px"}} onClick={handleAdd}>
                    <Add />
                </Fab>
            }
            <PopupDB action={actionPopup} data={modifyingData} extraData={[]}  table="local" open={popupOpen} setOpen={setPopupOpen} setDataChange={setDataChange}/>
        </>
    )
}