import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import API from "../services/API";
import Dropdown from "../components/Dropdown";
import Input from "../components/Input";
import { Check, Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Number from "../components/Number";






export default function Inventaire({}){
    const [locaux, setLocaux] = useState([]);
    const [emplacements, setEmplacements] = useState([]);

    const [selectedLocal, setSelectedLocal] = useState(undefined);
    const [produitsAAfficher, setProduitsAAfficher] = useState({});

    const [saisieValider, setSaisieValider] = useState({});
    const [validationEnCours, setValidationEnCours] = useState(false);

    const [saisieTransferer, setSaisieTransferer] = useState({});
    const [transfertEnCours, setTransfertEnCours] = useState(false);

    const [refreshData, setRefreshData] = useState(true);

    useEffect(() => {
        API("GET","local")
        .then(res=>{
            setLocaux(res.json)
            setSelectedLocal(res.json[0].nom)
        })


        API("GET","emplacement")
        .then(res=>setEmplacements(res.json))
    }, []);

    useEffect(() => {
        if(emplacements.length===0) return
        if(selectedLocal===undefined && !refreshData) return
        setProduitsAAfficher({})

        setRefreshData(false)

        let emplacements_local = emplacements.filter(e=>e.Local_nom===selectedLocal).map(e=>{return e.nom})

        API("GET","Produit",{},{emplacement_defaut:emplacements_local})
        .then(res=>{
            if(res.status === 200){
                let toSave = {}
                res.json.forEach(p=>{
                    toSave[p.id]=p
                    toSave[p.id]["stocks"] = {}
                    toSave[p.id]["stocks"][p.Emplacement_defaut] = 0
                })
                setProduitsAAfficher(toSave)
    
                API("GET","Mouvement_Stock/Stock", {}, {emplacements:emplacements_local})
                .then(res=>{
                    if(res.status === 200){
                        res.json.forEach(s=>{
                            if(!(s.id in toSave)){
                                toSave[s.id]=s
                                toSave[s.id]["stocks"] = {}
                            }
                            toSave[s.id]["stocks"][s.Emplacement_nom] = s.stock
                        })
                        setProduitsAAfficher(toSave)
                    }
                })
            }
        })


    }, [selectedLocal, emplacements, refreshData]);


    const handleChangeSaisieValider = (produit_id, emplacement, quantite)=>{
        setSaisieValider(currVal=>{
            let temp = {...currVal}
            if(! (produit_id in temp)){
                temp[produit_id]={}
            }
            temp[produit_id][emplacement] = quantite
            return temp
        })
    }

    const handleValider= ()=>{
        setValidationEnCours(true)
        let mvts = []
        Object.values(produitsAAfficher).forEach(p=>{
            Object.entries(p.stocks).forEach(([emp,stockInitial])=>{
                if(p.id in saisieValider && emp in saisieValider[p.id] && saisieValider[p.id][emp]*1 != stockInitial*1){
                    mvts.push({
                        Produit_id : p.id, 
                        Emplacement_nom : emp, 
                        quantite : saisieValider[p.id][emp] - stockInitial,
                        motif : "Inventaire"
                    })
                }
            })
        })

        API("POST","mouvement_stock",mvts)
        .then(res=>{
            if(res.status ===201){
                setSaisieValider({})
                setRefreshData(true)
            }
            setValidationEnCours(false)
        })

    }

    const handleChangeSaisieTransferer = (local, produit_id, quantite, empDest)=>{
        setSaisieTransferer(currVal=>{
            let temp = {...currVal}
            if(! (temp.hasOwnProperty(local))){
                temp[local]={}
            }
            if(! (temp[local].hasOwnProperty(produit_id))){
                temp[local][produit_id]=[]
            }
            if(empDest===undefined){        //si modif de la quantité
                temp[local][produit_id][0] = quantite
                if(quantite===undefined){   //si quantité effacée, efface l'emplacement
                    temp[local][produit_id][1] = undefined
                }
            }else{                          // si modif de l'emplacement
                temp[local][produit_id][1] = empDest
            }
            return temp
        })
    }

    const transfertPossible = (local)=>{
        console.log(local)
        if(!saisieTransferer.hasOwnProperty(local)) return false

        return Object.values(saisieTransferer[local]).reduce((acc,curr)=>{
            return acc || (curr[0] !==undefined && curr[1] !== undefined)
        },false)
    }

    const handleTransferer = (local)=>{
        setTransfertEnCours(local)

        let mvts = []
        Object.entries(saisieTransferer[local]).forEach(([produit_id, transfert])=>{
            if(transfert[0]===undefined) return
            //transfert.forEach(([empDest, quantite])=>{
                mvts.push({
                    Produit_id : produit_id, 
                    Emplacement_nom : transfert[1], 
                    quantite : transfert[0],
                    motif : "Transfert"
                })

                let quantiteRestante = transfert[0]
                let quantiteEmplacementDefaut = produitsAAfficher[produit_id].stocks[produitsAAfficher[produit_id]["Emplacement_defaut"]]
                if(quantiteEmplacementDefaut !== undefined && quantiteEmplacementDefaut > 0){
                    mvts.push({
                        Produit_id : produit_id, 
                        Emplacement_nom : produitsAAfficher[produit_id]["Emplacement_defaut"], 
                        quantite : -1 * Math.min(transfert[0], quantiteEmplacementDefaut),
                        motif : "Transfert"
                    })
                    quantiteRestante -= quantiteEmplacementDefaut
                }
                Object.entries(produitsAAfficher[produit_id].stocks).forEach(([empSrc,quantiteEmpSrc])=>{
                    if(quantiteRestante <= 0) return

                    mvts.push({
                        Produit_id : produit_id, 
                        Emplacement_nom : empSrc, 
                        quantite : -1 * Math.min(quantiteEmpSrc, quantiteRestante),
                        motif : "Transfert"
                    })
                    quantiteRestante -= quantiteEmpSrc
                })
            //})
        })

        API("POST","mouvement_stock",mvts)
        .then(res=>{
            if(res.status ===201){
                setSaisieTransferer(currVal=>{
                    let temp = {...currVal}
                    delete temp[local]
                    return temp
                })
                setRefreshData(true)
            }
            setTransfertEnCours(false)
        })

    }

    /*useEffect(() => {
        console.log(produitsAAfficher)
    }, [produitsAAfficher]);*/

    useEffect(() => {
        console.log(saisieTransferer)
    }, [saisieTransferer]);

    return(
        <>
            <Typography variant="h5" sx={{textAlign:'center'}}>Inventaire</Typography>
            <Box sx={{width:"100%", overflowX:"auto", whiteSpace:"nowrap"}}>
                <Box sx={{borderBottom:"2px solid black"}}>
                    <Box sx={{width:"49%",display:"inline-block"}}>
                        <Typography variant="h6" sx={{display:"inline-block", verticalAlign:"middle"}}>Local : </Typography>
                        {locaux.length>0 && <Dropdown options={locaux.map(l=>l.nom)} onChange={setSelectedLocal} defaultValue={locaux[0].nom} style={{width:150, verticalAlign:"middle"}} otherProps={{size:"small"}}/>}
                    </Box>
                    {locaux.map(l=>(
                        <Box key={l.nom} sx={{width:"25%",display:"inline-block", textAlign:'center', borderLeft:"1px solid black"}}>
                            <Typography variant="h6" sx={{}}>{l.nom}</Typography>
                        </Box>
                    ))}
                </Box>
                <Box sx={{}}>
                    <Box sx={{width:"49%",display:"inline-block"}}>
                        <Box sx={{width:"33%",display:"inline-block",textAlign:"center",verticalAlign:"middle", fontWeight:"bold"}}>Produit</Box>
                        <Box sx={{width:"33%",display:"inline-block",textAlign:"center",verticalAlign:"middle", fontWeight:"bold", whiteSpace: "normal"}}>Emplacement par défaut</Box>
                        <Box sx={{width:"33%",display:"inline-block",textAlign:"center",verticalAlign:"middle", fontWeight:"bold"}}>Stock total</Box>
                    </Box>
                </Box>
                {Object.values(produitsAAfficher).map(p=>(
                    <Box key={p.id} sx={{borderBottom:"1px dashed gray"}}>
                        <Box sx={{width:"49%",display:"inline-block",verticalAlign:"middle"}}>
                            <Box sx={{width:"33%",display:"inline-block",verticalAlign:"middle"}}>{p.nom}</Box>
                            <Box sx={{width:"33%",display:"inline-block",textAlign:"center",verticalAlign:"middle"}}>{p.Emplacement_defaut}</Box>
                            <Box sx={{width:"33%",display:"inline-block",textAlign:"center",verticalAlign:"middle"}}>{Object.values(p.stocks).reduce((acc,v)=>{return acc+(+v)},0)}</Box>
                        </Box>
                        {locaux.map(l=>(
                            <Box key={l.nom} sx={{width:"25%",display:"inline-block", textAlign:'center', borderLeft:"1px solid black",verticalAlign:"middle"}}>
                                {l.nom === selectedLocal && Object.entries(p.stocks).map(([emp,stk])=>(
                                    <Box sx={{m:1}}>
                                        <Box sx={{display:"inline-block",verticalAlign:"middle", mr:1}}>{+stk} en {emp}</Box>
                                        <TextField variant="outlined" style={{width:70,verticalAlign:"middle"}} size="small" onChange={(e)=>handleChangeSaisieValider(p.id, emp, e.target.value)} value={saisieValider[p.id]===undefined?"":saisieValider[p.id][emp]} />
                                    </Box>
                                ))}
                                {l.nom !== selectedLocal && 
                                    <Box sx={{m:1}}>
                                        <Number onChange={(v)=>handleChangeSaisieTransferer(l.nom, p.id, v)} maximum={Object.values(p.stocks).reduce((acc,v)=>{return acc+(+v)},0)} inputProps={{size:"small"}} style={{width:70,verticalAlign:"middle"}}/>
                                        {saisieTransferer.hasOwnProperty(l.nom) && saisieTransferer[l.nom].hasOwnProperty(p.id) && saisieTransferer[l.nom][p.id][0] !== undefined &&
                                            <Dropdown addFirstDisabledOption={true} options={emplacements.filter(e=>e.Local_nom===l.nom).map(e=>e.nom)} onChange={(v)=>handleChangeSaisieTransferer(l.nom, p.id, undefined, v)} style={{width:70}} otherProps={{size:"small"}}/>
                                        }
                                    </Box>  
                                }
                            </Box>
                        ))}
                    </Box>
                ))}
                
                <Box sx={{  }}>
                    <Box sx={{width:"49%",display:"inline-block"}}></Box>
                    {locaux.map(l=>(
                        <Box key={l.nom} sx={{pt : 3, width:"25%",display:"inline-block", textAlign:'center', borderLeft:"1px solid black",verticalAlign:"middle"}}>
                            {l.nom === selectedLocal &&
                                <LoadingButton disabled={Object.values(saisieValider).length===0} loading={validationEnCours} variant="contained" onClick={handleValider}><Check/> Valider</LoadingButton>
                            }
                            {l.nom !==selectedLocal &&
                                <LoadingButton disabled={!transfertPossible(l.nom)} loading={transfertEnCours===l.nom}  onClick={()=>handleTransferer(l.nom)} variant="contained"><Download/>Transférer</LoadingButton>
                            }
                        </Box>
                    ))}
                </Box>
            </Box>
        </>
    )
}