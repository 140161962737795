import { useContext, useEffect, useState } from 'react';
import {Drawer, Typography, Container, Box, Button, Icon, IconButton, Menu, MenuItem} from '@mui/material';
import Link from '@mui/material/Link';
import {BrowserRouter as Router, Redirect, Route, Routes, NavLink, useMatch, useResolvedPath, resolvePath, Navigate, useNavigate, useParams } from 'react-router-dom';
import {AddCircleOutline, SettingsSuggest, LocalShipping, FactCheck, ContactPage, MoveToInbox, Recycling,  ExitToApp, AccountCircle, BorderLeft, Person} from '@mui/icons-material';
import {BsDatabase} from "react-icons/bs"
import { useTheme } from '@mui/material/styles';
import { UserContext } from "../services/UserContext"
import { BlockingContext, BlockingProvider } from "../services/BlockingContext"
import PriseCommande from './PriseCommande';
import PrepaCommande from './PrepaCommande';
import LivraisonCommande from './LivraisonCommande';
import API from '../services/API';
import AccesBDD from './AccesBDD';
import Inventaire from './Inventaire';
import ReorganisationLocal from './ReorganisationLocal';
import CommandeFrn from './CommandeFrn';
import ReceptionFrn from './ReceptionFrn';
import { RequireRole } from '../services/Utils';
import Utilisateurs from './Utilisateurs';
import Utilisateur from './Utilisateur';


export default function Accueil(){
    const [userContext, setUserContext] = useContext(UserContext)
    const [anchorEl, setAnchorEl] = useState(null);
    let navigate = useNavigate();

    const styleIcons = {
        position: "absolute",
        left: 20,
        top: "50%",
        transform: "translateY(-50%)",
        height: 24,
        width: 24
    }
    const styleLinkTexts ={
        marginLeft: 20,
        display: "block"
    }

    useEffect(()=>{
        if(!!userContext.prenom) return
        API("GET","utilisateur/me",{},{},"application/json",{credentials:"include"}, userContext.token)
        .then(res=>{
            if (res.ok) {
                setUserContext(currVal => ({...currVal, ...res.json[0] }))
            } else {
                if (res.status === 401) {
                  // Edge case: when the token has expired.
                  // This could happen if the refreshToken calls have failed due to network error or
                  // User has had the tab open from previous day and tries to click on the Fetch button
                  window.location.reload()
                } 
              }
        })
    },[userContext])
    


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout=()=>{
        API("GET","utilisateur/logout",{},{},"application/json",{credentials:"include"}, userContext.token)
        .then(res=>{
            console.log("logout",res)
            setUserContext(currVal => ({ ...currVal, token: null }))

        })
    }

    const drawerWidth = 180

    return(
        <BlockingProvider>
            <Box sx={{ display: 'flex' }}>
                <Box component="nav" sx={{ width: drawerWidth , flexShrink: 0  }}>
                    <Drawer variant="permanent" anchor="left" sx={{width: 180, '& .MuiDrawer-paper': {width: 180,boxSizing: 'border-box', bgcolor:"primary.main"}}} >
                        <Typography sx={{color:"white", textAlign:"center",mb:3, fontSize:25}}>Boucherie d’Uriage</Typography>
                        <CustomLink to="prise_commande" otherPath="/"><AddCircleOutline sx={styleIcons}/><span style={styleLinkTexts}>Prise de<br/>commande</span></CustomLink>
                        <CustomLink to="preparation_commande"><SettingsSuggest sx={styleIcons}/><span style={styleLinkTexts}>Préparation<br/>commande</span></CustomLink>
                        <CustomLink to="livraison_commande"><LocalShipping sx={styleIcons}/><span style={styleLinkTexts}>Livraison<br/>commande</span></CustomLink>
                        <CustomLink to="inventaire"><FactCheck sx={styleIcons}/><span style={styleLinkTexts}>Inventaire</span></CustomLink>
                        <CustomLink to="reorganisation_local"><Recycling sx={styleIcons}/><span style={styleLinkTexts}>Réorganisation<br/>local</span></CustomLink>
                        <CustomLink to="commande_fournisseur"><ContactPage sx={styleIcons}/><span style={styleLinkTexts}>Commande<br/>fournisseur</span></CustomLink>
                        <CustomLink to="reception_fournisseur"><MoveToInbox sx={styleIcons}/><span style={styleLinkTexts}>Réception<br/>fournisseur</span></CustomLink>
                        <CustomLink to="acces_bdd/Commandes" root="acces_bdd"><BsDatabase style={styleIcons}/><span style={styleLinkTexts}>Accès base<br/>de données</span></CustomLink>

                        {userContext.role === "admin" && 
                            <CustomLink to="utilisateurs"><Person/><span style={{verticalAlign:"super"}}>Utilisateurs</span></CustomLink>
                        }

                        <Box sx={{mt:"auto", pt:"50px"}}>
                            <Typography sx={{color:"gris.contour", fontSize:"0.85rem", textAlign:"center"}}>Bonjour {userContext.prenom}</Typography>
                            <IconButton sx={{color:"gris.contour", display:"block", margin:"auto"}} onClick={handleMenu}><AccountCircle/></IconButton>
                            <Menu anchorEl={anchorEl} anchorOrigin={{vertical: 'bottom',horizontal: 'center',}} keepMounted
                                transformOrigin={{vertical: 'top',horizontal: 'center',}} open={Boolean(anchorEl)} onClose={handleClose}>
                                <MenuItem onClick={()=>navigate("/utilisateur/"+userContext.id)}><AccountCircle/>Profil</MenuItem>
                                <MenuItem onClick={handleLogout}><ExitToApp/>Déconnexion</MenuItem>
                            </Menu>
                        </Box>
                    </Drawer>
                </Box>
                <Box component="main" maxWidth="lg" sx={{ flexGrow: 1, pt:3, width: `calc(100% - ${drawerWidth}px)`, marginX:"auto", minWidth:"900px", px:2}}>
                    <Routes>
                        <Route path="/" element={<PriseCommande/>}/>
                        <Route path="prise_commande" element={<PriseCommande/>}/>
                        <Route path="prise_commande/:id" element={<PriseCommande/>}/>
                        <Route path="preparation_commande" element={<PrepaCommande/>}/>
                        <Route path="livraison_commande" element={<LivraisonCommande/>}/>
                        <Route path="inventaire" element={<Inventaire/>}/>
                        <Route path="reorganisation_local" element={<ReorganisationLocal/>}/>
                        <Route path="commande_fournisseur" element={<CommandeFrn/>}/>
                        <Route path="reception_fournisseur" element={<ReceptionFrn/>}/>
                        <Route path="acces_bdd/*" element={<AccesBDD/>}/>
                        <Route path="utilisateurs" element={<RequireRole roles={["admin"]}><Utilisateurs/></RequireRole>}/>
                        <Route path="utilisateur/:id" element={<RequireRole roles={["admin", "me"]}><Utilisateur/></RequireRole>}/>
                    </Routes>
                </Box>
            </Box>
        </BlockingProvider>
    )
}

function CustomLink({children, to,  root="", otherPath="impossiblePath", ...props  }) {
    const [blockingContext, setBlockingContext] = useContext(BlockingContext)
    const navigate = useNavigate();
    const theme = useTheme()
    let resolved = useResolvedPath(root===""?to:root);
    let match = useMatch({ path: resolved.pathname, end: false });
    resolved = useResolvedPath(otherPath);
    match = useMatch({ path: resolved.pathname, end: true }) || match
  
    const handleNavigation = () => {
        if(blockingContext){
            const result = window.confirm("La commande actuelle n'est pas enregistrée. Vous allez perdre vos données. Etes vous sûr ?");
            if (!result) {
                return;
            }
        }
        setBlockingContext(false)
        navigate(to);
    };
  
    return (
        <Link
            style={{cursor:"pointer", color: match ? "white" : theme.palette.gris.contour, textDecoration:"none", textAlign:"center", marginTop:20, position:"relative", marginLeft:"10px", borderLeft:match?"2px white solid":""}} 
            onClick={handleNavigation}
        >
            {children}
        </Link>
    );
  }
