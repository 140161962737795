import { useEffect, useState, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import API from '../../services/API';
import { Fab, IconButton } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import PopupDB from './PopupDB';
import {useSnackbar} from 'notistack';
import { UserContext } from "../../services/UserContext"




export default function Produits(){
    const [produits, setProduits] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false)
    const [dataChange, setDataChange] = useState(true)
    const [actionPopup, setActionPopup] = useState("Créer")
    const [modifyingData, setModifyingData] = useState({})
    const [userContext, setUserContext] = useContext(UserContext)

    const { enqueueSnackbar } = useSnackbar();

    let columns = [
        { field: 'id'},
        { field: 'nom', headerName: 'Nom', width:300},
        { field: 'secteur', headerName: 'Secteur'},
        { field: 'Groupe_Produit', headerName: 'Groupe'},
        { field: 'Sous_Groupe_Produit', headerName: 'Sous-groupe'},
        { field: 'Emplacement_defaut', headerName: 'Emplacement par défaut'},
        { field: 'unite_achat', headerName: 'Unité d\'achat'},
        { field: 'unite_vente', headerName: 'Unité de vente'},
        { field: 'taux_conversion_unites', headerName: 'Taux de conversion entre unités'},
        { field: 'prix', headerName: 'Prix de vente'},
        { field: 'vendable', headerName: 'Vendable'},
        { field: 'conseil_quantite', headerName: 'Conseil quantité'},
        { field: 'conseil_cuisson', headerName: 'Conseil cuisson'},
        { field: 'option_1', headerName: 'Option 1'},
        { field: 'option_2', headerName: 'Option 2'},
        { field: 'temps_init', headerName: 'Temps init'},
        { field: 'temps_ligne', headerName: 'Temps ligne'},
        { field: 'temps_unite', headerName: 'Temps unite'},
        { field: 'temps_cuit', headerName: 'Temps cuit'},
        { field: 'occupation_four', headerName: 'Occupation four'}
    ];
    if(userContext.role!=="standard"){
        columns = [
            {field: 'actions', headerName: 'Actions', sortable: false,
                renderCell: (params) => {
                  const onClickModify = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    handleModify(params.row)
                  };
                  const onClickDelete = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    let conf = window.confirm("Etes-vous sûr de vouloir supprimer ce produit ? Pas de retour en arrière ...")
                    if(conf){
                        handleDelete(params.row)
                    }
                  };
            
                  return(
                      <>
                          <IconButton color="primary" component="span" onClick={onClickModify}><Edit /></IconButton>
                          <IconButton color="primary" component="span" onClick={onClickDelete}><Delete /></IconButton>
                      </>
                  )
                },
            },
            ...columns
        ]
    }

    useEffect(()=>{
        if(!dataChange) return
        API('GET','Produit')
        .then(res=>setProduits(res.json))
        setDataChange(false)
    },[dataChange])

    const handleAdd = ()=>{
        setActionPopup("Créer")
        setModifyingData({})
        setPopupOpen(true)
    }

    const handleModify = (data)=>{
        setActionPopup("Modifier")
        setModifyingData(data)
        setPopupOpen(true)
    }
    const handleDelete = (data)=>{
        API("DELETE","Produit",{id:data.id})
        .then(res=>{
            if(!res.ok){//ERREUR
                enqueueSnackbar("Erreur lors de la suppression",{variant: "error"})
            }else{
                enqueueSnackbar("Suppression réussie",{variant: "success"})
            }
            setDataChange(true)
        })
    }

    return (
        <>
            <DataGrid
                rows={produits}
                columns={columns}
                disableSelectionOnClick 
                autoHeight
            />
            {userContext.role!=="standard" && 
                <Fab color="primary" aria-label="Add" sx={{position: "fixed", bottom: "10px", right: "10px"}} onClick={handleAdd}>
                    <Add />
                </Fab>
            }
            <PopupDB action={actionPopup} data={modifyingData} extraData={[]}  table="produit" open={popupOpen} setOpen={setPopupOpen} setDataChange={setDataChange}/>
        </>
    )
}