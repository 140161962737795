import React, {useState, useEffect} from 'react';
import Input from './Input';
import { TextField, InputAdornment } from '@mui/material';
import {NumericFormat} from 'react-number-format';


export default function Number({name, defaultValue, required, allowNegative, decimalScale, minimum, maximum, ornementFin, onChange, disabled, style, inputProps, refreshDefaultValue}){
    const [value, setValue] = useState(defaultValue)
    const [error, setError] = useState(false)

    const inputProps2 = {
        endAdornment: <InputAdornment position="end"><span style={{fontSize:"1rem"}}>{ornementFin}</span></InputAdornment>,
        style :{fontSize:"1rem", paddingRight:"14px"}
    }

    useEffect(()=>{
        setValue(defaultValue)
        onChange(defaultValue)
    },[defaultValue])
    useEffect(()=>{
        if(!refreshDefaultValue) return
        setValue(defaultValue)
        onChange(defaultValue)
    },[refreshDefaultValue])

    const handleChange2=(values, src)=>{
        //console.log(values)

        setValue(values.floatValue)
        onChange(values.floatValue)

        if(required && values.floatValue===undefined){
            setError(true)
        }else{
            setError(false)
        }
    }
    const handleChange=(event)=>{
        let v = event.target.value
        /*let maximumReached = false
        if(!isNaN(maximum)){
            if(v*1>maximum*1){
                v=maximum
                maximumReached = true
            }
        }*/

        setValue(v)
        if(v ===""){
            onChange(undefined)
            if(required){
                setError(true)
            }else{
                setError(false)
            }
        }else{
            setError(false)
            //onChange(v, maximumReached)
            onChange(v*1)
        }
    } 


    return(
        <NumericFormat
            allowNegative={false||allowNegative}
            customInput={TextField}
            decimalScale={decimalScale} 
            disabled={disabled}
            style={style} //{{width:"100%"}}

            label={name?name + (required?" *":""):""}
            value={value}
            required={required}
            error={error}
            variant="outlined"
            InputProps={{...inputProps, ...inputProps2}}
            //onChange={handleChange}
            onValueChange={handleChange2}
            isAllowed={(values) => {    
                const {floatValue} = values;
                if(floatValue===undefined) return true
                if(maximum !==undefined && minimum !==undefined){
                    return floatValue >= minimum &&  floatValue <= maximum
                }else if(maximum !==undefined){
                    return floatValue <= maximum
                }else if(minimum !==undefined){
                    return floatValue >= minimum
                }else{
                    return true
                }
            }}
        />
    )
}
