import React, { useEffect, useState } from "react"
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Icon, IconButton, Snackbar, Tooltip  } from "@mui/material"
import API from "../../services/API"
import { Add, Delete, NotInterestedRounded, RestoreFromTrash, Help } from '@mui/icons-material';
import {useSnackbar} from 'notistack';
import Dropdown from "../../components/Dropdown"
import SearchableDropdown from "../../components/SearchableDropdown"
import Number from "../../components/Number"
import Input from "../../components/Input"
import CheckboxItem from "../../components/CheckboxItem";
import Textarea from "../../components/Textarea";
import dayjs from "dayjs";
import DatePicker2 from "../../components/DatePicker2";

var _ = require('lodash');


export default function PopupDB({action, table, extraTable, extraTableMultipleAnswers, open, setOpen, setDataChange, data}){
    const [structTable, setStructTable] = useState([])
    const [structExtraTable, setStructExtraTable] = useState([])
    const [reponses, setReponses] = useState({init:undefined})
    const [extraData, setExtraData] = useState([])
    const [extraReponses, setExtraReponses] = useState([])
    const [extraMandatoryFields, setExtraMandatoryFields] = useState([])
    const [formNonValide, setFormNonValide] = useState(true)
    
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        if(!open) return
        API("GET",table + "/DESCRIBE")
        .then(res => {
            if(!res.ok) return //erreur
            res=res.json
            let defaultAnswers = {}
            let promises = [""]
            res.map((x,index)=>{
                if(res[index].Comment)
                    res[index].Comment = JSON.parse(res[index].Comment)
                if(res[index].Key=="MUL" && res[index].Comment.referTo){
                    promises.push(
                        API(res[index].Comment.referTo.method, res[index].Comment.referTo.path)
                        .then(values => {
                            if(!values.ok) return //erreur
                            values=values.json
                            res[index].Options =  values.map(v => ({id:eval("v."+res[index].Comment.referTo.key), name:eval("v."+res[index].Comment.referTo.name)}))
                        })
                    )
                }
                
                if(x.Null=="NO" && x.Key != "PRI"){
                    defaultAnswers[x.Field]=undefined
                }
            })
            if(action==="Modifier"){
                defaultAnswers = data;
            }
            setReponses(defaultAnswers)
            Promise.all(promises).then(v=>{
                setStructTable(res)
            })
        }, err => console.log(err))
    },[table, open])

    /*useEffect(()=>{
        if(!open || !extraTable) return
        API("GET",extraTable + "/DESCRIBE")
        .then(res => {
            if(!res.ok) return //erreur
            res=res.json
            let defaultAnswers = {}
            let promises = [""]
            res.map((x,index)=>{
                if(res[index].Comment)
                    res[index].Comment = JSON.parse(res[index].Comment)
                if(res[index].Comment.referTo){ //if(res[index].Key=="MUL" && res[index].Comment.referTo){
                    promises.push(
                        API(res[index].Comment.referTo.method, res[index].Comment.referTo.path)
                        .then(values => {
                            if(!values.ok) return //erreur
                            values=values.json
                            res[index].Options =  values.map(v => ({id:eval("v."+res[index].Comment.referTo.key), name:eval("v."+res[index].Comment.referTo.name)}))
                        })
                    )
                }
                if(res[index].Field === table+"_id"){
                    res[index].hidden = true
                }

                if(x.Null=="NO" && x.Field != "id" && res[index].Field !== table+"_id"){
                    //defaultAnswers[x.Field]=undefined
                    setExtraMandatoryFields(currVal=>[...currVal,x.Field])
                }
            })
            //setExtraReponses(defaultAnswers)
            Promise.all(promises).then(v=>{
                setStructExtraTable(res)
            })
        }, err => console.log(err))
    },[extraTable, open])*/

    useEffect(()=>{
        if(action!=="Modifier" || !open) return
        if(extraTable===undefined) return
        API("GET",extraTable,"",data)
        .then(res=>{
            if(!res.ok) return //erreur
            res=res.json
            setExtraData(res)
            setExtraReponses(res)
        })
    },[action, open])

    const handleChange = (field, value)=>{
        //if(value===undefined) return
        setReponses(currVal=>{
            if(value===undefined){
                delete currVal[field]
            }else{
                currVal = {...currVal,[field]:value}
            }
            return currVal
        })
    }

    const handleExtraChange = (values)=>{
        setExtraReponses(values)
    }

    useEffect(()=>{
        const answers = Object.values(reponses)
        let isKo = !answers.reduce((x,y)=>x&&(y!==undefined),true)

        if(extraMandatoryFields.length!==0){
            isKo = isKo || (!extraMandatoryFields.reduce((acc,field)=>{
                let tmp = acc
                extraReponses.forEach(r=>{
                    tmp = tmp&&(r[field]!==undefined)
                })
                return tmp
            },true))
        }

        setFormNonValide(isKo)
    },[reponses,extraReponses])

    const submitForm = () => {
        if(action==="Créer"){
            API("POST",table,reponses)
            .then(res=>{
                if(!res.ok){
                    enqueueSnackbar("Erreur lors de la création dans "+table+" : "+res.json.message,{variant: "error"})
                    return null
                }
                enqueueSnackbar("Création dans "+table+" réussie",{variant: "success"})
                setDataChange(true)
                resetForm()
                /*let er
                if(structExtraTable.find(e=>e.Field===table+"_id")){
                    er = extraReponses.map(e=>({...e,[table+"_id"]:res.json.insertId}))
                }else{
                    er = extraReponses      // au cas où, pas sûr que l'occasion se présente
                } 
                if(!extraTable || er.length===0) return
                API("POST",extraTable,er)
                .then(res=>{
                    if(!res.ok){//ERREUR
                        enqueueSnackbar("Erreur lors d'ajout(s) dans "+extraTable+" : "+res.json.messageFr,{variant: "error"})
                        return 
                    }else{
                        enqueueSnackbar("Ajout(s) dans "+extraTable+" réussi(s)",{variant: "success"})
                    }
                })*/
            })
        }else if(action==="Modifier"){
            if(reponses!==data){
                API("PATCH",table,reponses) 
                .then(res=>{
                    if(!res.ok){//ERREUR
                        enqueueSnackbar("Erreur lors de la modification de la ligne dans "+extraTable+" : "+res.json.message,{variant: "error"})
                    }else{
                        enqueueSnackbar("Modification de la ligne "+extraTable+" réussie",{variant: "success"})
                    }
                })
            }
            /*let toDelete = []
            let toCreate = []
            let toModify = []
            extraReponses.forEach(extraRep=>{
                let tmp = {...extraRep}
                delete tmp.i
                if(tmp.id === undefined){
                    toCreate.push({...tmp,[table+"_id"]:data.id})
                    
                }else{
                    if(tmp.toDelete){
                        toDelete.push(tmp)
                    }else{
                        delete tmp.toDelete
                        if(!_.isEqual(tmp, extraData.find(row=>row.id===tmp.id))){
                            toModify.push(tmp)
                        }
                    }
                }
                
            })
            if(toDelete.length){
                API("DELETE",extraTable,toDelete)
                .then(res=>{
                    if(!res.ok){//ERREUR
                        enqueueSnackbar("Erreur lors d'une suppression dans "+extraTable+" : "+res.json.message,{variant: "error"})
                    }else{
                        enqueueSnackbar("Suppression dans "+extraTable+" réussie",{variant: "success"})
                    }
                })
            }
            if(toModify.length){
                API("PUT",extraTable,toModify)
                .then(res=>{
                    if(!res.ok){//ERREUR
                        enqueueSnackbar("Erreur lors d'une modification dans "+extraTable+" : "+res.json.messageFr,{variant: "error"})
                    }else{
                        enqueueSnackbar("Modification dans "+extraTable+" réussie",{variant: "success"})
                    }
                })
            }
            if(toCreate.length){
                API("POST",extraTable,toCreate)
                .then(res=>{
                    if(!res.ok){//ERREUR
                        enqueueSnackbar("Erreur lors d'une création dans "+extraTable+" : "+res.json.messageFr,{variant: "error"})
                    }else{
                        enqueueSnackbar("Création dans "+extraTable+" réussie",{variant: "success"})
                    }
                })
            }*/
            setDataChange(true)
            resetForm()
        }
    }    

    const resetForm = () => {
        setReponses({init:undefined})
        setExtraReponses({})
        setExtraData([])
        setOpen(false)
    }
    
    return(
        <React.Fragment>
            <Dialog open={open}  aria-labelledby="responsive-dialog-title" fullWidth={true} maxWidth='md'>
                <DialogTitle id="responsive-dialog-title">{action} {table}</DialogTitle>
                <DialogContent className="Conteneur_Champ">
                    <Box sx={{display:"flex", flexWrap:"wrap", "& > .MuiFormControl-root":{m:2}, alignItems:"center"}}>
                        {structTable.map(i=>
                            <AfficheChamp key={i.Field} item={i} initValue={data[i.Field]} onChange={handleChange} action={action}/>
                        )}
                    </Box>
                    <AfficheMultiBlocsChampsExtra multi={extraTableMultipleAnswers} struct={structExtraTable} onChange={handleExtraChange} action={action} data={extraData} popupOpen={open}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetForm} variant="contained" color="secondary">
                        Annuler
                    </Button>
                    <Button disabled={formNonValide} onClick={submitForm} variant="contained" color="primary" autoFocus>
                        {action}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}


function AfficheMultiBlocsChampsExtra({multi,struct,onChange,action,data,popupOpen}){
    const [rowId, setRowId] = useState(0)
    const [reponsesExtra, setReponsesExtra] = useState([])


    useEffect(()=>{
        if(!popupOpen){
            setReponsesExtra([])
        }
    },[popupOpen])

    useEffect(()=>{
        if(!data || data.length===0) return
        addRow(data)
    },[data])


    const addRow = (data)=>{
        const tmp = data.map((row,index)=>({...row,i:rowId+index+1}))
        setReponsesExtra(currValRep=>[...currValRep,...tmp])
        setRowId(rowId+data.length)
        
    }
    const handleClickDelete = (type, index)=>{
        if(reponsesExtra[index].id){    // ligne en modification
            setReponsesExtra(currVal=>{
                currVal[index].toDelete = (type === "delete")
                return [...currVal]
            })
        }else{                          // nouvelle ligne
            setReponsesExtra(currVal=>{
                currVal.splice(index,1)
                return [...currVal]
            })
        }
        
    }

    const handleChange = (index,field,value)=>{
        setReponsesExtra(currVal=>{
            currVal[index][field] = value
            return [...currVal]
        })
    }

    useEffect(()=>{
        onChange(reponsesExtra)
    },[reponsesExtra])

    return(
        <React.Fragment>
            <Divider variant="middle"/>
            {multi && <Button variant="contained" color="primary" className="greenButton" startIcon={<Add/>} onClick={()=>addRow([{}])}>Ajouter une ligne</Button>}
            {reponsesExtra.map((v,index)=><AfficheBlocChampsExtra key={v.i} reponses={v} struct={struct} onChange={(field,value)=>handleChange(index,field,value)} handleClickDelete={(type)=>handleClickDelete(type,index)} action={action} initValue={data[index]}/>)}
        </React.Fragment>
    )
}
function AfficheBlocChampsExtra ({reponses, struct,onChange,handleClickDelete,action,initValue}){ 
    return(
        <React.Fragment>
            <Container className="flex blocChampsExtra">
                <Grid container spacing={1} className={"blocChampsExtra" + (reponses.toDelete ? " toDelete":"")} >
                    {struct.map(i=>
                        <Grid item key={reponses.i.toString().concat(i.Field)}>
                            <AfficheChamp item={i} initValue={initValue?initValue[i.Field]:undefined} onChange={onChange} action={action} isRowUnderModif={initValue && initValue.id!==undefined} />
                        </Grid>
                    )}
                </Grid>
                {!reponses.toDelete && <IconButton variant="contained" color="secondary" onClick={()=>handleClickDelete("delete")}><Delete/></IconButton>}
                {reponses.toDelete &&  <IconButton variant="contained" color="primary" onClick={()=>handleClickDelete("undelete")}><RestoreFromTrash/></IconButton>}
            </Container>
            <Divider variant="middle"/>
        </React.Fragment>
    )
}


const typeInputTab = {
    "":(key, name, defaultValue, required, options, disabled, onChange)=>null,
    "Input": (key, name, defaultValue, required, options, disabled, onChange)=><Input key={key} name={name} defaultValue={defaultValue} required={required} options={options} disabled={disabled} onChange={onChange} style={{width:"220px"}}/>,
    "Textarea": (key, name, defaultValue, required, options, disabled, onChange)=><Textarea key={key} name={name} defaultValue={defaultValue} required={required} options={options} disabled={disabled} onChange={onChange} style={{width:"220px"}}/>,
    "Dropdown": (key, name, defaultValue, required, options, disabled, onChange)=><Dropdown key={key} name={name} defaultValue={defaultValue} required={required} options={options} disabled={disabled} onChange={onChange} searchable={true} style={{width:"220px"}}/>,
    "SearchableDropdown": (key, name, defaultValue, required, options, disabled, onChange)=><SearchableDropdown key={key} name={name} defaultValue={defaultValue} required={required} options={options} disabled={disabled} onChange={onChange} searchable={true}/>,
    "Number": (key, name, defaultValue, required, options, disabled, onChange)=><Number key={key} name={name} defaultValue={defaultValue} required={required} options={options} disabled={disabled} onChange={onChange} style={{width:"220px"}}/>,
    "Checkbox": (key, name, defaultValue, required, options, disabled, onChange)=><CheckboxItem key={key} name={name} defaultValue={defaultValue} onChange={onChange}/>,
    "DatePicker2": (key, name, defaultValue, required, options, disabled, onChange, otherProps)=><DatePicker2 key={key} name={name} defaultValue={defaultValue} required={required} options={options} disabled={disabled} onChange={onChange} style={{width:"220px"}} otherProps={otherProps}/>,
}


function AfficheChamp({item, initValue, onChange, action, prefixKey="", isRowUnderModif}) {
    const [localKey, setLocalKey] = useState(prefixKey)
    const [typeInput, setTypeInput] = useState("")
    const [disabled, setDisabled] = useState(false)
    const [options, setOptions] = useState([])
    const [required, setRequired] = useState(false)
    const [otherProps, setOtherProps] = useState({});
    const [localInitValue, setLocalInitValue] = useState(initValue)
    const [localItemField, setLocalItemField] = useState("")

    

    useEffect(()=>{
        if(!item || !item.Field || item.hidden || (action=="Créer" && item.Key === "PRI" && item.Extra==="auto_increment" /*item.Field == "id"*/)){
            setTypeInput("")
            return
        }
        setDisabled(isRowUnderModif && (item.Comment.onModifyDisabled||false))
        setLocalItemField(item.Field)
        setLocalKey(prefixKey.concat(item.Field))
        setRequired(item.Null=="NO")
        switch(item.Type.split('(')[0]){
            case "varchar":
                setTypeInput("Input")
                if(item.Type.slice(8,-1)>100)
                    setTypeInput("Textarea")
                break
            case "enum":
                setTypeInput("Dropdown")
                let tmp = /^enum\('(.+)'\)$/.exec(item.Type)
                let opt = tmp!==null ? tmp[1].split("','") : []
                if(!opt.includes(initValue)){
                    setLocalInitValue("")
                }
                setOptions(opt.map(o=>({id:o, name:o})))
                break
            case "smallint":
            case "int":
            case "bigint":
            case "decimal":
                if(item.Options){
                    (item.Comment.referTo.searchable)?setTypeInput("SearchableDropdown"):setTypeInput("Dropdown")
                    setOptions(item.Options)
                    if(!item.Options.map(o=>o.id).includes(initValue)){
                        setLocalInitValue("")
                    }
                }else{
                    setTypeInput("Number")
                }
                break
            case "tinyint":
                setTypeInput("Checkbox")
                setLocalInitValue((!initValue||initValue=="Non")?false:true)
                break;
            case "date":
                setTypeInput("DatePicker2")
                setLocalInitValue(dayjs(initValue))
                if(item.Comment.otherProps){
                    setOtherProps(item.Comment.otherProps)
                }
        }
        if(action==="Modifier" && item.Key==="PRI"/*item.Field == "id"*/){
            setDisabled(true)
        }
    },[])

    if(action==="Modifier" && item.Field == "id" && localInitValue==undefined){//nouvelle ligne 
        return(<div style={{width:"200px"}}></div>)
    }else{
        return(
            <>
                {typeInputTab[typeInput](localKey,localItemField, localInitValue, required, options, disabled, (value)=>onChange(localItemField,value), otherProps)}
                {item.Comment?.helpperText !== undefined &&
                    <Tooltip title={item.Comment.helpperText} sx={{ml:-2}}>
                        <Help />
                    </Tooltip>
                }
            </>
        )
    }
}

