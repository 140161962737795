import { Autocomplete, Box, Button, CircularProgress, Divider, IconButton, MenuItem, Modal, Paper, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import {LoadingButton} from '@mui/lab';
import { useEffect, useState } from "react";
import CheckboxItem from "../components/CheckboxItem";
import API from "../services/API";
import {Check, Close, Edit } from "@mui/icons-material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const styleTitresDateCommande = {
    fontSize : 16,
    fontWeight : "bold"
}

const styleToggleButton = {

}

const optionsEtat = [
    {label : "A faire", color:"red"},
    {label : "En préparation", color:"orange"},
    {label : "Préparé", color:"green"}
]

export default function PrepaCommande({}){
    const [selectedButton, setSelectedButton] = useState("A la commande");

    const [commandes, setCommandes] = useState([]);
    const [selectedCommande, setSelectedCommande] = useState(undefined);
    const [lignesCommande, setLignesCommande] = useState([]);
    const [loadingLignes, setLoadingLignes] = useState(false);

    const [emplacements, setEmplacements] = useState([]);
    const [disableChangeEmplacement, setDisableChangeEmplacement] = useState(true);

    const [selectedProduit, setSelectedProduit] = useState(undefined);

    const [joursVisualises, setJoursVisualises] = useState([]);

    const [globalEtat, setGlobalEtat] = useState("");

    let navigate = useNavigate();

    useEffect(() => {
        API('GET','commande',{},{uniquementAFaire:true, tri:"date_livraison,numero"})
        .then(res=>{
            const commandes_temp = res.json
            setCommandes(commandes_temp)

            API('GET','ligne_commande',{},{avecProduit:true, Commande_numero:commandes_temp.map(c=>c.numero).join(',')})
            .then(res=>{
                let temp = res.json.sort((a,b)=>{return commandes_temp.findIndex(c=>c.numero===a.Commande_numero)-commandes_temp.findIndex(c=>c.numero===b.Commande_numero)})
                temp = temp.map(lc=>({...lc, date_livraison:commandes_temp.find(c=>c.numero===lc.Commande_numero).date_livraison}))
                setLignesCommande(temp)
                console.log("LIGNES",temp)
            })
        })

        API('GET','emplacement')
        .then(res=>setEmplacements(res.json.map(e=>e.nom)))
    }, []);

    useEffect(() => {
        setSelectedCommande(undefined)
        setSelectedProduit(undefined)
    }, [selectedButton]);
    
    useEffect(() => {
        setDisableChangeEmplacement(true)
    }, [selectedButton, selectedCommande, lignesCommande]);

    const handleEnableChangeEmplacement = ()=>{
        setDisableChangeEmplacement(currVal=>!currVal)
        //setDisableChangeEmplacement(false)
        //setTimeout(()=> setDisableChangeEmplacement(true), 5000)
    }

    const handleChangeEmplacement = (e, value, r)=>{
        let oldVal = selectedCommande
        setSelectedCommande(currVal=>({...currVal, Emplacement_nom:value}))

        API('PATCH',"commande",{numero:selectedCommande.numero, Emplacement_nom : value})
        .then(res=>{
            if(res.status !== 200){
                alert("Erreur lors de la modificaton de l'emplacement de la commande")
                console.error("res PATCH commande",res)
                setSelectedCommande(oldVal)
            }else{
                setCommandes(currVal=>{
                    currVal.find(c=>c.numero===selectedCommande.numero).Emplacement_nom = value
                    return currVal
                })
            }
        })
    }

    useEffect(() => {
        console.log("lignesCommande", lignesCommande)
    }, [lignesCommande]);

    /*const handleSelectCommande = (commande)=>{
        setSelectedCommande(commande)
        
    }*/
    const setLigne = (ligne)=>{
        setLignesCommande(currVal=>{
            let temp = [...currVal]
            let index = temp.findIndex(l=>l.id===ligne.id)
            temp[index] = ligne
            return temp
        })
    }

    const setEmplacementCommande = (commande_numero, emplacement)=>{
        setCommandes(currVal=>{
            let temp = [...currVal]
            let index = temp.findIndex(c=>c.numero===commande_numero)
            temp[index].Emplacement_nom = emplacement
            return temp
        })
    }

    const handleChangeGlobalEtat = ()=>{
        const alc = selectedButton==="A la commande"

        lignesCommande.forEach((l)=>{
            if(!alc && l.Produit_id===selectedProduit.id || alc && l.Commande_numero===selectedCommande.numero){
                if(compareEtat(globalEtat, l.etat)>0){
                    API("PATCH","ligne_commande",{id:l.id,etat:globalEtat})
                }
            }
        })

        setLignesCommande(currVal=>{
            let temp = [...currVal]
            temp.forEach((l)=>{
                if(!alc && l.Produit_id===selectedProduit.id || alc && l.Commande_numero===selectedCommande.numero){
                    if(compareEtat(globalEtat, l.etat)>0){
                        l.etat=globalEtat
                    }
                }
            })
            return temp
        })
        
        setGlobalEtat("")
    }

    const livrerCommande = ()=>{
        /*setLivraisonEnCours(true)
        API("PATCH","commande",{numero:selectedCommande.numero, etat:"Livrée"})
        .then(res=>{
            if(res.status !== 200){
                alert("Erreur lors de la modificaton de la commande")
                console.error("res PATCH commande",res)
            }else{
                commandes.splice(commandes.findIndex(c=>c.numero===selectedCommande.numero), 1)
                setSelectedCommande(undefined)
                setLignesCommande([])
            }
            setLivraisonEnCours(false)
        })*/
    }

/*
    useEffect(() => {
        console.log(lignesCommande.map(lc=>lc.date_livraison));
        console.log(joursVisualises);
    }, [joursVisualises]);*/


    const now = dayjs()
    const commandesAnciennes = commandes.filter(c=>dayjs(c.date_livraison)<now.startOf('day'))
    const commandesDuJour = commandes.filter(c=>dayjs(c.date_livraison)>=now.startOf('day') && dayjs(c.date_livraison)<=now.endOf('day'))
    const commandesDemain = commandes.filter(c=>dayjs(c.date_livraison)>=now.add(1,'day').startOf('day') && dayjs(c.date_livraison)<=now.add(1,'day').endOf('day'))
    const commandesPlusTard = commandes.filter(c=>dayjs(c.date_livraison)>now.add(1,'day').endOf('day'))

    const produitsDisctinctSecteur = Array.from(new Set(lignesCommande.filter(l=>l.Produit_secteur===selectedButton).map(l=>({id:l.Produit_id, nom:l.Produit_nom, unite_vente:l.Produit_unite, secteur:l.Produit_secteur})).map(JSON.stringify)), JSON.parse);

    return (
        <>
            <Typography variant="h5" sx={{textAlign:'center'}}>Préparation de commande</Typography>
            <Box sx={{display:"flex"}}>
                <Box sx={{display:"flex", flexDirection:"column", width:"50%", mr:2, borderRight: "2px black solid", height:"calc(100vh - 60px)"}}>
                    <Box sx={{borderBottom:"2px black solid"}}>
                        <Box sx={{width:300, m:"auto", display:"flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                            <Button sx={{m:1}} onClick={()=>setSelectedButton("A la commande")} variant={selectedButton==="A la commande"?"contained":"outlined"}>A la commande</Button>
                            <Button sx={{m:1, float:"right"}} onClick={()=>setSelectedButton("Boucherie")} variant={selectedButton==="Boucherie"?"contained":"outlined"}>Boucherie</Button>
                            <Button sx={{m:1}} onClick={()=>setSelectedButton("Charcuterie")} variant={selectedButton==="Charcuterie"?"contained":"outlined"}>Charcuterie</Button>
                            <Button sx={{m:1, float:"right"}} onClick={()=>setSelectedButton("Traiteur")} variant={selectedButton==="Traiteur"?"contained":"outlined"}>Traiteur</Button>
                        </Box>
                    </Box>
                    <Box sx={{maxHeight:"calc(100vh - 170px)", overflowY:"auto"}}>
                        <Box sx={{px:2, ml:3, fontSize:16}}>
                            <span style={{width:"40%", display:"inline-block", textAlign:"left"}}>{selectedButton === "A la commande" ? "N° commande":"Produit"}</span>
                            <span style={{width:"30%", display:"inline-block", textAlign:"center"}}>A faire</span>
                            <span style={{width:"30%", display:"inline-block", textAlign:"right"}}>En préparation</span>
                        </Box>
                        {selectedButton === "A la commande" &&
                        <>
                            {commandesAnciennes.length>0 && 
                                <Box>
                                    <Typography sx={styleTitresDateCommande}>Anciennes</Typography>
                                    {commandesAnciennes.map(c=>{
                                        return <CardCommande key={c.numero} commande={c} lignesCommande={lignesCommande.filter(l=>l.Commande_numero===c.numero)} onClick={()=>setSelectedCommande(c)} selected={selectedCommande?.numero === c.numero}/>
                                    })}
                                </Box>
                            }
                            <Box>
                                <Typography sx={styleTitresDateCommande}>Aujourd'hui - Matin</Typography>
                                {commandesDuJour.filter(c=>c.heure_livraison==="Matin").map(c=>{
                                    return <CardCommande key={c.numero} commande={c} lignesCommande={lignesCommande.filter(l=>l.Commande_numero===c.numero)} onClick={()=>setSelectedCommande(c)} selected={selectedCommande?.numero === c.numero}/>
                                })}
                            </Box>
                            <Box>
                                <Typography sx={styleTitresDateCommande}>Aujourd'hui - Après-midi</Typography>
                                {commandesDuJour.filter(c=>c.heure_livraison==="Après-midi").map(c=>{
                                    return <CardCommande key={c.numero} commande={c} lignesCommande={lignesCommande.filter(l=>l.Commande_numero===c.numero)} onClick={()=>setSelectedCommande(c)} selected={selectedCommande?.numero === c.numero}/>
                                })}
                            </Box>
                            <Box>
                                <Typography sx={styleTitresDateCommande}>Demain</Typography>
                                {commandesDemain.map(c=>{
                                    return <CardCommande key={c.numero} commande={c} lignesCommande={lignesCommande.filter(l=>l.Commande_numero===c.numero)} onClick={()=>setSelectedCommande(c)} selected={selectedCommande?.numero === c.numero}/>
                                })}
                            </Box>
                            {commandesPlusTard.length>0 &&
                                <Box>
                                    <Typography sx={styleTitresDateCommande}>Plus tard</Typography>
                                    {commandesPlusTard.map(c=>{
                                        return <CardCommande key={c.numero} commande={c} lignesCommande={lignesCommande.filter(l=>l.Commande_numero===c.numero)} onClick={()=>setSelectedCommande(c)} selected={selectedCommande?.numero === c.numero}/>
                                    })}
                                </Box>
                            }
                        </>
                        }
                        {selectedButton !== "A la commande" &&
                        <Box>
                            {produitsDisctinctSecteur.map(p=>{
                                return <CardProduit key={p.id} produit={p} lignesCommande={lignesCommande.filter(l=>l.Produit_id===p.id)} onClick={()=>setSelectedProduit(p)} selected={selectedProduit?.id === p.id}/>
                            })}
                        </Box>
                        }
                    </Box>
                </Box>
                <Box sx={{width:"50%"/*,display: "flex", flexDirection:"column"*/}}>
                    {loadingLignes && 
                        <CircularProgress sx={{m:"auto"}} />
                    }






                    {selectedButton==="A la commande" && selectedCommande!==undefined &&
                        <>
                        <Box sx={{height:100, mb:2}}>
                            <Typography variant="h6" sx={{pl:2, textAlign:"center"}}>Commande n° {selectedCommande?.numero?.toString().padStart(5, '0')}</Typography>
                            <Typography variant="h7" sx={{pl:2, textAlign:"center", display:"block"}}>Date de livraison : {dayjs(selectedCommande?.date_livraison).format("DD-MM-YYYY")}</Typography>
                            <Box sx={{display:"flex", pl:2, alignItems: "center", justifyContent:"center"}}>
                                <Typography variant="h7" sx={{mr:2}}>Emplacement : </Typography>
                                <Autocomplete
                                    options={emplacements}
                                    renderInput={(params) => <TextField {...params} size="small" />}
                                    onChange={handleChangeEmplacement}
                                    value={selectedCommande?.Emplacement_nom}
                                    sx={{width:150, mt:1}}
                                    disabled={disableChangeEmplacement}
                                />
                                <IconButton onClick={handleEnableChangeEmplacement} color="primary">{disableChangeEmplacement && <Edit/>}{!disableChangeEmplacement && <Close/>}</IconButton>
                            </Box>
                        </Box>
                        <Box sx={{height:"calc(100vh - 175px)", overflowY:"auto", display:"flex", flexDirection:"column"}}>
                            {lignesCommande.filter(l=>l.Commande_numero===selectedCommande.numero).sort((a,b)=>a.Produit_nom<b.Produit_nom?-1:1).map(ligne=>{
                                return <BlocLigneCommande key={ligne.id} ligne={ligne} setLigne={setLigne}/>
                            })}

                            <Box sx={{display:"flex", flexWrap:"wrap", justifyContent:"center", mt:"auto", mb:2}}>
                                <div style={{height:"20px", width:"100%"}}><Divider/></div>
                                <div style={{width:130, mt:4}} >Modifier l'état de toutes les lignes</div>
                                <TextField select value={globalEtat} onChange={(ev)=>setGlobalEtat(ev.target.value)} size="small" sx={{width:"35%", textAlign:"center", "& > .MuiInputBase-root":{width:"100%", color:optionsEtat.find(o=>o.label===globalEtat)?.color||"black"}}} > 
                                    {optionsEtat.map((o)=>(
                                        <MenuItem key={o.label} value={o.label} sx={{color:o.color}}>{o.label}</MenuItem>
                                        ))}
                                </TextField>
                                <IconButton color="primary" onClick={handleChangeGlobalEtat}><Check/></IconButton>
                                <div style={{marginTop:"10px", marginBottom:"10px", width:"100%"}}><Divider/></div>
                                <Button onClick={()=>navigate("/prise_commande/"+selectedCommande.numero)} sx={{}} variant="contained" color="primary">Modifier la commande</Button>
                            </Box>
                            
                        </Box>
                        </>
                    }
                    {selectedButton!=="A la commande" && selectedProduit!==undefined &&
                        <>
                        <Box sx={{height:65, mb:2}}>
                            <Typography variant="h6" sx={{textAlign:"center"}}>Produit : {selectedProduit?.nom}</Typography>
                            <Box sx={{textAlign:"center"}}>
                                <CheckboxItem name="Aujourd'hui" defaultValue={true} onChange={(v)=>setJoursVisualises(currVal=>[v?dayjs().format("YYYY-MM-DD"):undefined, currVal[1]])} sx={{mr:6}}/>
                                <CheckboxItem name="Demain" defaultValue={false} onChange={(v)=>setJoursVisualises(currVal=>[currVal[0], v?dayjs().add(1,'day').format("YYYY-MM-DD"):undefined])} sx={{mr:0}}/>
                            </Box>
                        </Box>
                        <Box sx={{height:"calc(100vh - 102px)", overflowY:"auto", display:"flex", flexDirection:"column"}}>
                            {lignesCommande.filter(l=>l.Produit_id===selectedProduit.id && joursVisualises.includes(l.date_livraison)).map(ligne=>{
                                return <BlocLigneProduit key={ligne.id} ligne={ligne} setLigne={setLigne} commande={commandes.find(c=>c.numero === ligne.Commande_numero)} setEmplacementCommande={setEmplacementCommande} emplacements={emplacements}/>
                            })}

                            
                            <Box sx={{display:"flex", flexWrap:"wrap", justifyContent:"center", mt:"auto", mb:2}}>
                                <div style={{height:"20px", width:"100%"}}><Divider/></div>
                                <div style={{width:130, mt:4}} >Modifier l'état de toutes les lignes</div>
                                <TextField select value={globalEtat} onChange={(ev)=>setGlobalEtat(ev.target.value)} size="small" sx={{width:"35%", textAlign:"center", "& > .MuiInputBase-root":{width:"100%", color:optionsEtat.find(o=>o.label===globalEtat)?.color||"black"}}} > 
                                    {optionsEtat.map((o)=>(
                                        <MenuItem key={o.label} value={o.label} sx={{color:o.color}}>{o.label}</MenuItem>
                                    ))}
                                </TextField>
                                <IconButton color="primary" onClick={handleChangeGlobalEtat}><Check/></IconButton>
                            </Box>
                        </Box>
                        </>
                    }

                </Box>
            </Box>
        </>
    )
}

function CardCommande({commande, lignesCommande, selected, onClick}){

    return(
        <Paper onClick={()=>onClick(commande)} elevation={3} sx={{width:"calc(100% - 64px)", height:30, m:1, px:2, ml:3, textAlign:"center", lineHeight: "30px", cursor:"pointer", backgroundColor:selected?"primary.main":"white", color:selected?"white":"black"}}>
            <div style={{width:"40%", display:"inline-block", textAlign:"left"}}>{commande.numero}</div>
            <div style={{width:"30%", display:"inline-block"}}>{lignesCommande.reduce((total,acc)=>total+=acc.etat=="A faire",0)}</div>
            <div style={{width:"30%", display:"inline-block", textAlign:"right"}}>{lignesCommande.reduce((total,acc)=>total+=acc.etat=="En préparation",0)}</div>
        </Paper>                  
    )
}
function CardProduit({produit, lignesCommande, selected, onClick}){

    return(
        <Paper onClick={()=>onClick(produit)} elevation={3} sx={{width:"calc(100% - 64px)", height:30, m:1, px:2, ml:3, textAlign:"center", lineHeight: "30px", cursor:"pointer", backgroundColor:selected?"primary.main":"white", color:selected?"white":"black"}}>
            <div style={{width:"40%", display:"inline-block", textAlign:"left"}}>{produit.nom}</div>
            <div style={{width:"30%", display:"inline-block"}}>{lignesCommande.reduce((total,acc)=>total+=acc.etat=="A faire",0)}</div>
            <div style={{width:"30%", display:"inline-block", textAlign:"right"}}>{lignesCommande.reduce((total,acc)=>total+=acc.etat=="En préparation",0)}</div>
        </Paper>                  
    )
}


function BlocLigneCommande({ligne, setLigne}){

    useEffect(() => {
        //console.log("detailLigne", ligne)
    }, [ligne]);

    let commun ={
        display:"inline-block",
        fontSize:"18px"

    }
    



    return(
        <Box sx={{my:"20px"}}>
            <Typography sx={{...commun, width:"65%", fontWeight: "bold"}}>{ligne.Produit_nom}</Typography>
            <SelectEtat ligne={ligne} setLigne={setLigne} />
            {/*<TextField select value={ligne.etat} onChange={handleChangeEtat} size="small" sx={{...commun, width:"35%", textAlign:"center", "& > .MuiInputBase-root":{width:"100%", color:optionsEtat.find(o=>o.label===ligne.etat)?.color||"black"}}} > 
                {optionsEtat.map((o)=>(
                    <MenuItem key={o.label} value={o.label} sx={{color:o.color}}>{o.label}</MenuItem>
                ))}
                </TextField>*/}
            <Box sx={{display:"flex", flexWrap:"wrap", width:"65%", color:"darkgray"}}>
                <Typography sx={{width:"100%"}}>Quantité : {ligne.quantite} {ligne.Produit_unite}</Typography>
                {ligne.option_1 && 
                    <Typography>{ligne.option_1}</Typography>
                }
                {ligne.option_2 && 
                    <><Typography> - </Typography>
                    <Typography>{ligne.option_2}</Typography></>
                }
                {ligne.commentaire && 
                    <Typography sx={{width:"100%",overflowWrap: "break-word"}}>{ligne.commentaire}</Typography>
                }
            </Box>
        </Box>
    )
}

function SelectEtat({ligne, setLigne}){
    const [emplacementDefaut, setEmplacementDefaut] = useState("");
    const [stock, setStock] = useState({});
    const [mvtStockDejaRealise, setMvtStockDejaRealise] = useState(undefined);

    useEffect(() => {
        API("GET", "Mouvement_stock",{},{Ligne_Commande_id: ligne.id, motif:"Livraison client"})
        .then(res=>{
            if(res.status===200){
                setMvtStockDejaRealise(true)
            }else{
                setMvtStockDejaRealise(false)
            }
        })
    }, []);

    useEffect(() => {
        if(mvtStockDejaRealise === false){
            API("GET","Mouvement_stock/stock",{},{Produit_id:ligne.Produit_id})
            .then(res=>{
                let tmp = {}
                res.json.forEach(s=>{
                    tmp[s.Emplacement_nom] = s.stock
                })
                setStock(tmp)
                setEmplacementDefaut(res.json[0]?.Emplacement_defaut)
            })
        }
    }, [mvtStockDejaRealise]);

    const optionsEtat = [
        {label : "A faire", color:"red"},
        {label : "En préparation", color:"orange"},
        {label : "Préparé", color:"green"}
    ]

    const handleChangeEtat = (event)=>{
        const etat = event.target.value
        setLigne({...ligne, etat:etat})
        API("PATCH","ligne_commande",{id:ligne.id, etat:etat})
        .then(res=>{
            if(res.status !== 200){
                alert("Erreur lors de la modificaton de l'état de la ligne de commande")
                console.error("res PATCH ligne_commande",res)
            }
        })

        debugger

        if(mvtStockDejaRealise === false){
            let commun = {Produit_id:ligne.Produit_id, motif:"Livraison client", Ligne_Commande_id:ligne.id}
            let toPost = []
            let qteRestante = ligne.quantite
            if(Object.keys(stock).length===0){
                toPost.push({...commun, Emplacement_nom:emplacementDefaut, quantite:0})
            }else{
                if(stock[emplacementDefaut] > 0){
                    let qte = Math.min(stock[emplacementDefaut], qteRestante)
                    toPost.push({...commun, Emplacement_nom:emplacementDefaut, quantite:-1*qte})
                    qteRestante -= qte
                }

                let i=0
                let keys = Object.keys(stock)
                while(qteRestante>0 || i<keys.length){
                    if(keys[i] !== emplacementDefaut){
                        let qte = Math.min(stock[keys[i]], qteRestante)
                        toPost.push({...commun, Emplacement_nom:keys[i], quantite:-1*qte})
                        qteRestante -= qte
                    }
                    i++
                }
            }
            API("POST","Mouvement_stock",toPost)
            .then(res=>{
                if(res.status !== 201){
                    alert("Erreur lors de l'ajout de(s) mouvements de stock")
                    console.error("res POST Mouvement_stock",res)
                }
            })
        }
    }

    return (
        <TextField select value={ligne.etat} onChange={handleChangeEtat} size="small" sx={{display:"inline-block", fontSize:"18px", width:"35%", textAlign:"center", "& > .MuiInputBase-root":{width:"100%", color:optionsEtat.find(o=>o.label===ligne.etat)?.color||"black"}}} > 
            <MenuItem value="A faire" sx={{color:"red"}} disabled={ligne.etat!="A faire"}>A faire</MenuItem>
            <MenuItem value="En préparation" sx={{color:"orange"}}>En préparation</MenuItem>
            <MenuItem value="Préparé" sx={{color:"green"}}>Préparé</MenuItem>
        </TextField>
    )
}

function BlocLigneProduit({ligne, setLigne, commande, setEmplacementCommande, emplacements}){

    useEffect(() => {
        //console.log("detailLigne", ligne)
    }, [ligne]);

    let commun ={
        display:"inline-block",
        fontSize:"18px"

    }

    const handleChangeEtat = (event)=>{
        const etat = event.target.value
        setLigne({...ligne, etat:etat})
        API("PATCH","ligne_commande",{id:ligne.id, etat:etat})
        .then(res=>{
            if(res.status !== 200){
                alert("Erreur lors de la modificaton de l'état de la ligne de commande")
                console.error("res PATCH ligne_commande",res)
            }
        })
    }

    const handleChangeEmplacement = (e,value, r)=>{
        setEmplacementCommande(commande.numero, value)
        API("PATCH","commande",{numero:commande.numero, Emplacement_nom:value})
        .then(res=>{
            if(res.status !== 200){
                alert("Erreur lors de la modificaton de l'emplacement de la commande")
                console.error("res PATCH commande",res)
            }
        })
    }

    const beautifyDateLivraison = ()=>{
        if(commande.date_livraison === dayjs().format("YYYY-MM-DD")){
            return "Aujourd'hui - "+commande.heure_livraison
        }else if(commande.date_livraison === dayjs().add(1,"day").format("YYYY-MM-DD")){
            return "Demain - "+commande.heure_livraison
        }else{
            return dayjs(commande.date_livraison).format("DD/MM/YYYY")
        }
    }

    return(
        <Box sx={{my:"20px"}}>
            <Typography sx={{...commun, width:"65%", fontWeight: "bold"}}>Commande n° {ligne.Commande_numero.toString().padStart(5, '0')}</Typography>
            <TextField select value={ligne.etat} onChange={handleChangeEtat} size="small" sx={{...commun, width:"35%", textAlign:"center", "& > .MuiInputBase-root":{width:"100%", color:optionsEtat.find(o=>o.label===ligne.etat)?.color||"black"}}} > 
                {optionsEtat.map((o)=>(
                    <MenuItem key={o.label} value={o.label} sx={{color:o.color}}>{o.label}</MenuItem>
                ))}
            </TextField>
            <Box sx={{display:"flex", flexWrap:"wrap", width:"65%", color:"darkgray"}}>
                <Typography sx={{width:"100%"}}>Date de livraison : {beautifyDateLivraison()}</Typography>
                <Typography sx={{width:"100%"}}>Quantité : {ligne.quantite} {ligne.Produit_unite}</Typography>
                {ligne.option_1 && 
                    <Typography>{ligne.option_1}</Typography>
                }
                {ligne.option_2 && 
                    <><Typography> - </Typography>
                    <Typography>{ligne.option_2}</Typography></>
                }
                {ligne.commentaire && 
                    <Typography sx={{width:"100%",overflowWrap: "break-word"}}>{ligne.commentaire}</Typography>
                }
            </Box>
            <Box sx={{display:"flex", pl:2, alignItems: "center"}}>
                <Typography variant="h7" sx={{mr:2}}>Emplacement : </Typography>
                <Autocomplete
                    options={emplacements}
                    renderInput={(params) => <TextField {...params} size="small" />}
                    onChange={handleChangeEmplacement}
                    value={commande.Emplacement_nom}
                    sx={{width:150, mt:1}}
                    disabled={commande.Emplacement_nom!=undefined}
                />
            </Box>
        </Box>
    )
}

function compareEtat(a,b){
    const tab ={
        "A faire" : 0,
        "En préparation" : 1,
        "Préparé" : 2
    }
    return tab[a] - tab[b]
}