import { Autocomplete, Box, Button, CircularProgress, IconButton, Modal, Paper, TextField, Typography } from "@mui/material";
import {LoadingButton} from '@mui/lab';
import { useEffect, useState } from "react";
import API from "../services/API";
import { AddCircle, Cancel, Search } from "@mui/icons-material";
import Input from "../components/Input";
import Dropdown from "../components/Dropdown";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Number from "../components/Number";

const styleTitresDateCommande = {
    fontSize : 16,
    fontWeight : "bold"
}

export default function LivraisonCommande({}){
    const [openAutocompleteClient, setOpenAutocompleteClient] = useState(false);
    const [clients, setClients] = useState([]);
    const [inputClient, setInputClient] = useState("");
    const [selectedClient, setSelectedClient] = useState(undefined);

    const [commandes, setCommandes] = useState([]);
    const [commandesAffichees, setCommandesAffichees] = useState([]);
    const [selectedCommande, setSelectedCommande] = useState(undefined);
    const [lignesCommande, setLignesCommande] = useState([]);
    const [loadingLignes, setLoadingLignes] = useState(false);

    const [livraisonEnCours, setLivraisonEnCours] = useState(false);

    useEffect(() => {
        API('GET','client')
        .then(res=>setClients(res.json))

        API('GET','commande',{},{uniquementAFaire:true, tri:"date_livraison,numero"})
        .then(res=>{
            setCommandes(res.json)
            setCommandesAffichees(res.json)
        })

    }, []);


    const handleKeyDown = (event)=>{
        if (event.key !== 'Enter'){
            setOpenAutocompleteClient(false)
            return
        }  

        event.defaultMuiPrevented = true;
        search()
    }
    const search = ()=>{
        if(inputClient){
            setOpenAutocompleteClient(true)
        }
    }
    const handleClientChange = (event,value, reason)=>{
        if(reason==="selectOption"){
            setSelectedClient(value)
        }
    }

    useEffect(() => {
        if(selectedClient){
            setCommandesAffichees(commandes.filter(c=>c.Client_id==selectedClient.id))
        }else{
            setCommandesAffichees(commandes)
        }
    }, [selectedClient]);


    const handleSelectCommande = (commande)=>{
        setLoadingLignes(true)
        setSelectedCommande(commande)
        setLignesCommande([])
        
        API("GET","ligne_commande",{},{Commande_numero:commande.numero, avecProduit:true, orderBy:"Produit_nom"})
        .then(res=>{
            setLignesCommande(res.json)
            setLoadingLignes(false)
        })
    }

    const livrerCommande = ()=>{
        setLivraisonEnCours(true)
        API("PATCH","commande",{numero:selectedCommande.numero, etat:"Livrée"})
        .then(res=>{
            if(res.status !== 200){
                alert("Erreur lors de la modificaton de la commande")
                console.error("res PATCH commande",res)
            }else{
                commandes.splice(commandes.findIndex(c=>c.numero===selectedCommande.numero), 1)
                setSelectedCommande(undefined)
                setLignesCommande([])
            }
            setLivraisonEnCours(false)
        })

/*
        let mvtsStock = []
        lignesCommande.forEach((lc)=>{
            mvtsStock.push({Produit_id:lc.Produit_iS, })
        })
        API("POST","mouvement_stock",{})
        .then(res=>)*/
    }

    const now = dayjs()
    const commandesAfficheesAnciennes = commandesAffichees.filter(c=>dayjs(c.date_livraison)<now.startOf('day'))
    const commandesAfficheesDuJour = commandesAffichees.filter(c=>dayjs(c.date_livraison)>=now.startOf('day') && dayjs(c.date_livraison)<=now.endOf('day'))
    const commandesAfficheesDemain = commandesAffichees.filter(c=>dayjs(c.date_livraison)>=now.add(1,'day').startOf('day') && dayjs(c.date_livraison)<=now.add(1,'day').endOf('day'))
    const commandesAfficheesPlusTard = commandesAffichees.filter(c=>dayjs(c.date_livraison)>now.add(1,'day').endOf('day'))
    const nbreLignesNonPreparees = lignesCommande.filter(l=>l.etat!=="Préparé").length

    return (
        <>
            <Typography variant="h5" sx={{textAlign:'center'}}>Livraison de commande</Typography>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{width:"50%", minHeight:"100px", borderRight: "2px black solid", borderBottom:"2px black solid"}}>
                    <Typography variant="h6">Client</Typography>
                    <Box sx={{display:"flex"}}>
                        {!selectedClient &&
                        <>
                            <Autocomplete
                                options={clients}
                                getOptionLabel={(option) => `${option.prenom} ${option.nom} - ${option.tel1}`}
                                renderInput={(params) => <TextField {...params} label="Téléphone ou nom" size="small" />}
                                open={openAutocompleteClient}
                                /*filterOptions={(options, state)=>{

                                }}*/
                                onKeyDown={handleKeyDown}
                                onInputChange={(e,i,r)=>setInputClient(i)}
                                onClose={()=>setOpenAutocompleteClient(false)}
                                onChange={handleClientChange}
                                forcePopupIcon={false}
                                clearOnBlur={false}
                                sx={{width:200}}
                            
                            />
                            <IconButton sx={{ml:"-40px"}} onClick={search}><Search/></IconButton>
                        </>
                        }
                        {!!selectedClient &&
                        <>
                            <Typography sx={{marginY:"auto"}}>{selectedClient?.prenom} {selectedClient.nom}</Typography>
                            <IconButton sx={{color:"secondary.main"}} onClick={()=>setSelectedClient(undefined)}><Cancel/></IconButton>
                        </>
                        }
                        
                    </Box>
                </Box>
                <Box sx={{width:"50%", minHeight:"100px", borderBottom:"2px black solid", mt:1}}>
                    <Typography variant="h6" sx={{pl:2}}>Commande n° {selectedCommande?.numero?.toString().padStart(5, '0')}</Typography>
                    <Box sx={{display:"flex", pl:2}}>
                        <Typography variant="h6" sx={{mr:2}}>Emplacement : </Typography>
                        <Typography>{selectedCommande?.Emplacement_nom||"Non défini"}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{width:"50%", maxHeight:"calc(100vh - 170px)", borderRight: "2px black solid", overflowY:"auto"}}>
                    <Box sx={{px:2, ml:3, fontSize:16}}>
                        <span style={{width:"30%", display:"inline-block", textAlign:"left"}}>N° commande</span>
                        <span style={{width:"40%", display:"inline-block", textAlign:"center"}}>Client</span>
                        <span style={{width:"30%", display:"inline-block", textAlign:"right"}}>Date de livraison</span>
                    </Box>
                    {commandesAfficheesAnciennes.length>0 &&
                        <Box>
                            <Typography sx={styleTitresDateCommande}>Anciennes</Typography>
                            {commandesAfficheesAnciennes.map(c=>{
                                return <CardCommande commande={c} client={clients.find(cl=>cl.id===c.Client_id)} onClick={handleSelectCommande} selected={selectedCommande?.numero === c.numero}/>
                            })}
                        </Box>
                    }
                    {commandesAfficheesDuJour.length>0 &&
                        <Box>
                            <Typography sx={styleTitresDateCommande}>Aujourd'hui</Typography>
                            {commandesAfficheesDuJour.map(c=>{
                                return <CardCommande commande={c} client={clients.find(cl=>cl.id===c.Client_id)} onClick={handleSelectCommande} selected={selectedCommande?.numero === c.numero}/>
                            })}
                        </Box>
                    }
                    {commandesAfficheesDemain.length>0 &&
                        <Box>
                            <Typography sx={styleTitresDateCommande}>Demain</Typography>
                            {commandesAfficheesDemain.map(c=>{
                                return <CardCommande commande={c} client={clients.find(cl=>cl.id===c.Client_id)} onClick={handleSelectCommande} selected={selectedCommande?.numero === c.numero}/>
                            })}
                        </Box>
                    }
                    {commandesAfficheesPlusTard.length>0 &&
                        <Box>
                            <Typography sx={styleTitresDateCommande}>Plus tard</Typography>
                            {commandesAfficheesPlusTard.map(c=>{
                                return <CardCommande commande={c} client={clients.find(cl=>cl.id===c.Client_id)} onClick={handleSelectCommande} selected={selectedCommande?.numero === c.numero}/>
                            })}
                        </Box>
                    }
                </Box>
                <Box sx={{width:"50%", height:"calc(100vh - 170px)", overflowY:"auto", display: "flex", flexDirection:"column"}}>
                    {loadingLignes && 
                        <CircularProgress sx={{m:"auto"}} />
                    }
                    <Box sx={{px:"40px"}}> 
                        {lignesCommande.map(ligne=>{
                            return <BlocLigneCommande detailLigne={ligne}/>
                        })}
                    </Box>
                    <Box sx={{px:"40px", mt:"auto", mb:2}}>
                        {nbreLignesNonPreparees>0 && 
                        <> 
                            <Typography sx={{display:"inline-block", width:"75%", fontWeight:"bold", color:"secondary.main"}}>Nombre de lignes non préparées</Typography>
                            <Typography sx={{display:"inline-block", width:"25%", textAlign:"right", color:"secondary.main"}}>{nbreLignesNonPreparees}</Typography>
                        </>
                        }
                        <Typography sx={{display:"inline-block", width:"75%", fontWeight:"bold"}}>Nombre de lignes</Typography>
                        <Typography sx={{display:"inline-block", width:"25%", textAlign:"right"}}>{lignesCommande.length}</Typography>
                        <Typography sx={{display:"inline-block", width:"75%", fontWeight:"bold"}}>Prix total estimatif</Typography>
                        <Typography sx={{display:"inline-block", width:"25%", textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(lignesCommande.reduce((total,l)=>total+(+l.prix), 0))}</Typography>
                        <LoadingButton disabled={selectedCommande===undefined} loading={livraisonEnCours} onClick={livrerCommande} variant="contained" color="primary" sx={{float:"right"}}>Livrer la commande</LoadingButton>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

function CardCommande({commande, client, selected, onClick}){

    return(
        <Paper onClick={()=>onClick(commande)} elevation={3} sx={{width:"calc(100% - 64px)", height:30, m:1, px:2, ml:3, textAlign:"center", lineHeight: "30px", cursor:"pointer", backgroundColor:selected?"primary.main":"white", color:selected?"white":"black"}}>
            <div style={{width:"25%", display:"inline-block", textAlign:"left"}}>{commande.numero}</div>
            <div style={{width:"50%", display:"inline-block"}}>{client?.prenom + " " + client?.nom}</div>
            <div style={{width:"25%", display:"inline-block", textAlign:"right"}}>{commande.date_livraison}</div>
        </Paper>                  
    )
}


function BlocLigneCommande({detailLigne}){

    /*useEffect(() => {
        console.log("detailLigne", detailLigne)
    }, [detailLigne]);*/

    let commun ={
        display:"inline-block",
        fontSize:"18px"

    }
    const colorsEtat = {
        "A faire":"red",
        "En préparation":"orange",
        "Préparé":"green"
    }
    return(
        <Box sx={{my:"20px"}}>
            <Typography sx={{...commun, width:"50%", fontWeight: "bold"}}>{detailLigne.Produit_nom} - <span style={{color:colorsEtat[detailLigne.etat]}}>{detailLigne.etat}</span></Typography>
            <Typography sx={{...commun, width:"25%", textAlign:"center"}}>{detailLigne.quantite} {detailLigne.Produit_unite}</Typography>
            <Typography sx={{...commun, width:"25%", textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(detailLigne.prix)}</Typography>
            <Box sx={{display:"flex", flexWrap:"wrap", width:"50%"}}>
                {detailLigne.option_1 && 
                    <Typography>{detailLigne.option_1}</Typography>
                }
                {detailLigne.option_2 && 
                    <><Typography> - </Typography>
                    <Typography>{detailLigne.option_2}</Typography></>
                }
                {detailLigne.commentaire && 
                    <Typography sx={{width:"100%",overflowWrap: "break-word"}}>{detailLigne.commentaire}</Typography>
                }
            </Box>
        </Box>
    )
}