import { Box, MenuItem, TextField } from "@mui/material"
import { useEffect, useState, useContext } from "react";
import { redirect, useParams, useNavigate, Routes, Route, useLocation, Navigate   } from "react-router-dom";
import Produits from "./accesBDD/Produits";
import {SnackbarProvider} from 'notistack';
import Emplacements from "./accesBDD/Emplacements";
import Locaux from "./accesBDD/Locaux";
import Groupes from "./accesBDD/Groupes";
import SousGroupes from "./accesBDD/SousGroupes";
import Clients from "./accesBDD/Clients";
import Commandes from "./accesBDD/Commandes";
import LignesCommande from "./accesBDD/LignesCommande";
import ProduitsPrevisionnels from "./accesBDD/ProduitsPrevisionnels";
import Fournisseurs from "./accesBDD/Fournisseurs";
import FournisseurConditions from "./accesBDD/FournisseurConditions";
import LignesCommandeFournisseur from "./accesBDD/LignesCommandeFournisseur";
import MouvementsStock from "./accesBDD/MouvementsStock";
import { UserContext } from "../services/UserContext"
import { RequireRole } from "../services/Utils";



const tablesStandard = [
    "Commandes",
    "Lignes-commande",
    "Mouvements-stock",
    "Produits",
    "Produits-Previsionnels",
    "Emplacements",
    "Locaux",
    "Groupes",
    "Sous-groupes"
]

const tablesAvance = [
    "Commandes",
    "Lignes-commande",
    "Mouvements-stock",
    "Produits",
    "Fournisseurs",
    "Fournisseur-Conditions",
    "Lignes-commande-fournisseur",
    "Produits-Previsionnels",
    "Emplacements",
    "Locaux",
    "Groupes",
    "Sous-groupes"
]

const tablesAdmin = [
    "Commandes",
    "Lignes-commande",
    "Mouvements-stock",
    "Produits",
    "Clients",
    "Fournisseurs",
    "Fournisseur-Conditions",
    "Lignes-commande-fournisseur",
    "Produits-Previsionnels",
    "Emplacements",
    "Locaux",
    "Groupes",
    "Sous-groupes"
]

/*class DropDown extends Component {
    onChange = (e) => {
      this.props.history.push(`/${e.target.value}`);
    }
    render() {
      return (
        <select onChange={this.onChange}>
          <option value="first-route">First</option>
          <option value="second-route">Second</option>
        </select>
      );
    }
  }

const Menu = withRouter(DropDown);*/

export default function AccesBDD(props){
    const [selectedTable, setSelectedTable] = useState("");
    const location = useLocation();
    const [userContext, setUserContext] = useContext(UserContext)

    useEffect(() => {
        let tmp = location.pathname.split('/')
        tmp = tmp[tmp.length - 1]
        console.log("location",tmp);
        setSelectedTable(tmp)
    }, [location]);

    const navigate = useNavigate();
    const handleChange = (ev)=>{
        setSelectedTable(ev.target.value)
        return navigate('/acces_bdd/'+ev.target.value)
    }

    let tables = tablesStandard
    if(userContext.role === "avance"){
        tables = tablesAvance
    }else if(userContext.role === "admin"){
        tables = tablesAdmin
    }

    return (
        <Box>
            <TextField select size="small" value={selectedTable} onChange={handleChange} sx={{mb:1}}> 
                {tables.map((t)=>(
                    <MenuItem key={t} value={t}>{t}</MenuItem>
                ))}
            </TextField>
            

            <SnackbarProvider maxSnack={5}>
                <Routes>
                        <Route path="Commandes" element={<Commandes/>}/>
                        <Route path="Lignes-commande" element={<LignesCommande/>}/>
                        <Route path="Mouvements-stock" element={<MouvementsStock/>}/>
                        <Route path="Clients" element={<RequireRole roles={["admin"]} defaultRedirect="/acces_bdd/Commandes"><Clients/></RequireRole>}/>
                        <Route path="Fournisseurs" element={<RequireRole roles={["admin","avance"]} defaultRedirect="/acces_bdd/Commandes"><Fournisseurs/></RequireRole>}/>
                        <Route path="Fournisseur-Conditions" element={<RequireRole roles={["admin","avance"]} defaultRedirect="/acces_bdd/Commandes"><FournisseurConditions/></RequireRole>}/>
                        <Route path="Lignes-commande-fournisseur" element={<RequireRole roles={["admin","avance"]} defaultRedirect="/acces_bdd/Commandes"><LignesCommandeFournisseur/></RequireRole>}/>
                        <Route path="Produits" element={<Produits/>}/>
                        <Route path="Produits-Previsionnels" element={<ProduitsPrevisionnels/>}/>
                        <Route path="Emplacements" element={<Emplacements/>}/>
                        <Route path="Locaux" element={<Locaux/>}/>
                        <Route path="Groupes" element={<Groupes/>}/>
                        <Route path="Sous-groupes" element={<SousGroupes/>}/>
                </Routes>
            </SnackbarProvider>
        </Box>
    )
}

