import { Autocomplete, Box, Button, IconButton, Modal, Paper, Stack, Switch, TextField, Typography, styled } from "@mui/material";
import {LoadingButton } from '@mui/lab';
import { useContext, useEffect, useState } from "react";
import API from "../services/API";
import { BlockingContext, BlockingProvider } from "../services/BlockingContext"
import { AddCircle, Cancel, Search } from "@mui/icons-material";
import Input from "../components/Input";
import Dropdown from "../components/Dropdown";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import Number from "../components/Number";
import {useParams, useNavigate} from "react-router-dom";
import {getJoursCommandeEtReception} from "../services/Utils"


const MatinApremSwitch = styled(Switch)(({ }) => ({
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
          transform: 'translateX(22px)',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: "#FAB036",
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: "#e48f05",
          },
        },
      },
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      opacity: 1,
      backgroundColor : "#9bafc7"
    },
    '& .MuiSwitch-thumb': {
      backgroundColor : "#7c96b5"
    },
  }));


export default function PriseCommande({}){
    const [blockingContext, setBlockingContext] = useContext(BlockingContext)
    //const [isBlocking, setIsBlocking] = useState(false);

    const [openAutocompleteClient, setOpenAutocompleteClient] = useState(false);
    const [clients, setClients] = useState([]);
    const [inputClient, setInputClient] = useState("");
    const [selectedClient, setSelectedClient] = useState(undefined);
    const [openCreationClient, setOpenCreationClient] = useState(false);

    const [produits, setProduits] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({id:undefined});
    const [openAjoutProduit, setOpenAjoutProduit] = useState(false);
    const [groupes, setGroupes] = useState([]);
    const [sousGroupes, setSousGroupes] = useState([]);
    const [groupeSelected, setGroupeSelected] = useState(undefined);
    const [sousGroupeSelected, setSousGroupeSelected] = useState(undefined);

    const [commande, setCommande] = useState({numero:undefined,Client_id:undefined, date_livraison:dayjs().format("YYYY-MM-DD")});
    const [lignesCommande, setLignesCommande] = useState([]);
    const [dateLivraison, setDateLivraison] = useState(dayjs());
    const [heureLivraison, setHeureLivraison] = useState(false);
    const [answersOk, setAnswersOk] = useState(false);
    const [creationEnCours, setCreationEnCours] = useState(false);
    const [ligneData, setLigneData] = useState(undefined);

    const [modificationsApportees, setModificationsApportees] = useState(false);

    
    let { id } = useParams();


    useEffect(() => {
        if(id===undefined){
            setSelectedClient(undefined)
            API('GET','client')
            .then(res=>setClients(res.json))
    
            chargerNumeroCommande()

            setLignesCommande([])
            setDateLivraison(dayjs())
        }else{
            API('GET',"commande",{},{numero:id})
            .then(res=>{
                const commande_temp = res.json[0]
                setCommande(commande_temp)
                setDateLivraison(dayjs(commande_temp.date_livraison))
                API("GET","client",{},{id:commande_temp.Client_id})
                .then(res=>setSelectedClient(res.json[0]))
            })

            API("GET","ligne_commande",{},{Commande_numero:id, avecProduit:true})
            .then(res=>setLignesCommande(res.json))
        }

        API('GET','produit',{},{vendable:"Oui"})
        .then(res=>setProduits(res.json))

        API('GET','groupe')
        .then(res=>setGroupes(res.json))

        API('GET','sous_groupe')
        .then(res=>setSousGroupes(res.json))
    }, [id]);

    const chargerNumeroCommande = ()=>{
        API('GET','commande/prochain_numero')
        .then(res=>setCommande(currVal=>({...currVal, numero:res.json[0].prochain_numero||1})))
    }

    const handleKeyDown = (event)=>{
        if (event.key !== 'Enter'){
            setOpenAutocompleteClient(false)
            return
        }  

        event.defaultMuiPrevented = true;
        search()
    }
    const search = ()=>{
        if(inputClient){
            setOpenAutocompleteClient(true)
        }
    }
    const handleClientChange = (event,value, reason)=>{
        if(reason==="selectOption"){
            setSelectedClient(value)
            setModificationsApportees(true)
        }
    }

    const callbackCreationClient = (value)=>{
        setOpenCreationClient(false)

        if(!value) return

        setSelectedClient(value)
        setModificationsApportees(true)
    }

    useEffect(() => {
        setCommande(currVal=>({...currVal, Client_id:selectedClient?.id}))
    }, [selectedClient]);


    const handleDateChange = (value,context)=>{
        setDateLivraison(value)
        setCommande(currVal=>({...currVal, date_livraison:value.format("YYYY-MM-DD")}))
        setModificationsApportees(true)
    }
    
    const handleHeureLivraisonChange = (event)=>{
        setHeureLivraison(event.target.checked)
        setCommande(currVal=>({...currVal, heure_livraison:event.target.checked?"Après-midi":"Matin"}))
        setModificationsApportees(true)
    }


    const handleProduitChange = (event, value, reason)=>{
        if(reason==="selectOption"){
            selectionProduit(value)
        }
    }
    const selectionProduit = (value)=>{
        setSelectedProduct(value)
        setOpenAjoutProduit(true)
    }
    const callbackPopupProduit = (repLigneCommande)=>{
        setOpenAjoutProduit(false)
        if(repLigneCommande!==false){
            repLigneCommande["prix"] = repLigneCommande.quantite * repLigneCommande.Produit_prix||0
            if(repLigneCommande.id===undefined){   //creation
                repLigneCommande["id"] = new Date().getTime()
                setLignesCommande(currVal => [...currVal, repLigneCommande])
            }else{ // modification ou suppression
                if(repLigneCommande.toDelete){
                    setLignesCommande(currVal => {
                        let temp = [...currVal]
                        const index = temp.findIndex(t=>t.id === repLigneCommande.id)
                        temp.splice(index, 1)
                        return temp
                    })
                }else{
                    setLignesCommande(currVal => {
                        let temp = [...currVal]
                        const index = temp.findIndex(t=>t.id === repLigneCommande.id)
                        temp[index] = repLigneCommande
                        return temp
                    })
                }
            }
        }
        clearProduct()
        setModificationsApportees(true)
    }

    useEffect(() => {
        setBlockingContext(id===undefined && lignesCommande.length>0 || modificationsApportees)
        setAnswersOk(Object.values(commande).filter(a=>a===undefined).length===0 && lignesCommande.length > 0 && (id===undefined || modificationsApportees))
    }, [commande, lignesCommande]);


    const handleModifyLigne = (index, ligne)=>{
        setSelectedProduct(produits.find(p=>p.id===ligne.Produit_id))
        setLigneData(ligne)
        setOpenAjoutProduit(true)
    }


    const clearGroupesSelection = ()=>{
        setSousGroupeSelected(undefined)
        setGroupeSelected(undefined)
    }
    const clearProduct = ()=>{
        setLigneData(undefined)
        setSelectedProduct({id:undefined})
        clearGroupesSelection()
    }
    const navigate = useNavigate();
    const resetPage = ()=>{
        setModificationsApportees(false)
        setBlockingContext(false)
        setCommande({numero:undefined,Client_id:undefined, date_livraison:dayjs().format("YYYY-MM-DD"), heure_livraison:"Matin"})
        setDateLivraison(dayjs())
        setHeureLivraison(false)
        setLignesCommande([])
        clearProduct()
        setSelectedClient(undefined)
        setBlockingContext(false)
        chargerNumeroCommande()
        navigate("/prise_commande")
    }

    const handleClickCommande = ()=>{
        setCreationEnCours(true)
        if(id === undefined){
            API("POST","commande",commande)
            .then(res=>{
                if(res.status != 201){
                    alert("Erreur lors de la création de la commande")
                    console.error("res POST commande",res)
                    setCreationEnCours(false)
                    return
                }
    
                API("POST","ligne_commande", lignesCommande.map(l=>({...l,Commande_numero:res.json.insertId})).map(l=>{delete l.id; return l}))
                .then(res=>{
                    if(res.status != 201){
                        alert("Erreur lors de la création des lignes de commande")
                        console.error("res POST ligne_commande",res)
                    }else{
                        resetPage()
                    }
                    setCreationEnCours(false)
                })
            })
        }else{
            let promises = []

            promises.push(API("PATCH","commande",commande)
            .then(res=>{
                if(res.status != 200){
                    alert("Erreur lors de la modification de la commande")
                    console.error("res PATCH commande",res)
                    setCreationEnCours(false)
                    return
                }
            }))
    
            //lignes à ajouter
            const lignesAAjouter = lignesCommande.filter(l=>l.id>999999999999) //=> id est un timestamp donc nouvelle ligne
            if(lignesAAjouter.length>0){
                promises.push(API("POST","ligne_commande", lignesAAjouter.map(l=>({...l,Commande_numero:commande.numero})))
                .then(res=>{
                    if(res.status != 201){
                        alert("Erreur lors de la création des lignes de commande")
                        console.error("res POST ligne_commande",res)
                    }
                }))
            }

            //mettre à jour toutes les autres lignes même si elles n'ont pas bougés  TODO:faire mieux
            lignesCommande.filter(l=>l.id<999999999999).forEach((l)=>{
                let temp = {...l}
                delete temp.Produit_nom
                delete temp.Produit_unite
                delete temp.Produit_prix
                delete temp.Produit_secteur
                promises.push(API("PATCH","ligne_commande", temp)
                .then(res=>{
                    if(res.status != 200){
                        alert("Erreur lors de la modification des lignes de commande")
                        console.error("res PATCH commande",res)
                    }
                }))
            })
            Promise.all(promises).then(allRes=>{
                setCreationEnCours(false) 
                resetPage()
            })
        }
    }

    return (
        <>
            {/*<Prompt when={isBlocking} message="La commande actuelle n'est pas enregistrée. Vous allez perdre vos données. Etes vous sûr ?"/>*/}
            <Typography variant="h5" sx={{textAlign:'center'}}>Prise de commande</Typography>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{width:"50%", minHeight:"100px", borderRight: "2px black solid", borderBottom:"2px black solid"}}>
                    <Typography variant="h6">Client</Typography>
                    <Box sx={{display:"flex"}}>
                        {!selectedClient &&
                        <>
                            <Autocomplete
                                options={clients}
                                getOptionLabel={(option) => `${option.prenom} ${option.nom} - ${option.tel1}`}
                                renderInput={(params) => <TextField {...params} label="Téléphone ou nom" size="small" />}
                                open={openAutocompleteClient}
                                /*filterOptions={(options, state)=>{

                                }}*/
                                onKeyDown={handleKeyDown}
                                onInputChange={(e,i,r)=>setInputClient(i)}
                                onClose={()=>setOpenAutocompleteClient(false)}
                                onChange={handleClientChange}
                                forcePopupIcon={false}
                                clearOnBlur={false}
                                sx={{width:200}}
                            
                            />
                            <IconButton sx={{ml:"-40px"}} onClick={search}><Search/></IconButton>
                            <Typography sx={{marginY:"auto", marginX:"60px"}}>OU</Typography>
                            <IconButton sx={{color:"primary.main"}} onClick={()=>setOpenCreationClient(true)}><AddCircle/></IconButton>

                        </>
                        }
                        {!!selectedClient &&
                        <>
                            <Typography sx={{marginY:"auto"}}>{selectedClient.prenom} {selectedClient.nom}</Typography>
                            <IconButton sx={{color:"secondary.main"}} onClick={()=>setSelectedClient(undefined)}><Cancel/></IconButton>
                        </>
                        }
                        
                    </Box>
                </Box>
                <Box sx={{width:"50%", minHeight:"100px", borderBottom:"2px black solid", mt:1}}>
                    <Typography variant="h6" sx={{pl:2}}>Commande n° {commande.numero?.toString().padStart(5, '0')}{id===undefined&&"-PROV"}</Typography>
                    <Box sx={{display:"flex", pl:2}}>
                        <Typography variant="h6" sx={{mr:2}}>Date de livraison : </Typography>
                        {/*<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr"> */}
                            <DatePicker onChange={handleDateChange} value={dateLivraison} minDate={dayjs()} format="DD-MM-YYYY" sx={{width:150, "& > .MuiInputBase-root > .MuiInputBase-input":{py:1}}} />
                            <Stack direction="row" alignItems="center" sx={{ml:2}}>
                                <Typography>Matin</Typography>
                                <MatinApremSwitch checked={heureLivraison} onChange={handleHeureLivraisonChange}/>
                                <Typography>Après-midi</Typography>
                            </Stack>
                        {/*</LocalizationProvider>*/}
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{width:"50%", maxHeight:"calc(100vh - 170px)", borderRight: "2px black solid", overflowY:"auto"}}>
                    <Autocomplete
                        options={produits}
                        getOptionLabel={(option) => option.nom}
                        renderInput={(params) => <TextField {...params} size="small" />}
                        onChange={handleProduitChange}
                        sx={{width:300, mx:"auto", mt:1}}
                        value={selectedProduct?.id?selectedProduct:null}
                    />

                    {groupeSelected !== undefined &&
                        <>
                            {groupeSelected}{sousGroupeSelected?" -> "+sousGroupeSelected:""}
                            <IconButton sx={{color:"secondary.main"}} onClick={clearGroupesSelection}><Cancel/></IconButton>
                        </>
                    }

                    {groupeSelected === undefined && 
                        <Box sx={{display: 'flex', flexWrap:"wrap", justifyContent:"space-around", mt:1}}>
                            {groupes.map(g=>{
                                return <Paper key={g.nom} onClick={()=>setGroupeSelected(g.nom)} elevation={3} sx={{width:150, height:150, m:1, textAlign:"center", lineHeight: "150px", cursor:"pointer"}}>{g.nom}</Paper>
                            })}
                        </Box>
                    }
                    {groupeSelected !== undefined && sousGroupeSelected === undefined && 
                        <Box sx={{display: 'flex', flexWrap:"wrap", justifyContent:"space-around", mt:1}}>
                            {sousGroupes.filter(sg=>sg.Groupe_Produit===groupeSelected).map(sg=>{
                                return <Paper key={sg.nom} onClick={()=>setSousGroupeSelected(sg.nom)} elevation={3} sx={{width:90, height:90, m:1, textAlign:"center", lineHeight: "90px", cursor:"pointer"}}>{sg.nom}</Paper>
                            })}
                        </Box>
                    }
                    {groupeSelected !== undefined && sousGroupeSelected !== undefined && 
                        <Box sx={{display: 'flex', flexWrap:"wrap", justifyContent:"space-around", mt:1}}>
                            {produits.filter(p=>p.Groupe_Produit===groupeSelected && p.Sous_Groupe_Produit===sousGroupeSelected).map(p=>{
                                return <Paper key={p.id} onClick={()=>selectionProduit(p)} elevation={3} sx={{width:90, height:30, m:1, textAlign:"center", lineHeight: "30px", cursor:"pointer"}}>{p.nom}</Paper>
                            })}
                        </Box>
                    }
                </Box>
                <Box sx={{width:"50%", height:"calc(100vh - 170px)", overflowY:"auto", display: "flex", flexDirection:"column"}}>
                    <Box sx={{px:"40px"}}> 
                        {lignesCommande.map((ligne,i)=>{
                            return <BlocLigneCommande detailLigne={ligne} onClick={()=>handleModifyLigne(i,ligne)}/>
                        })}
                    </Box>
                    <Box sx={{px:"40px", mt:"auto", mb:2}}>
                        <Typography sx={{display:"inline-block", width:"75%", fontWeight:"bold"}}>Prix total estimatif</Typography>
                        <Typography sx={{display:"inline-block", width:"25%", textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(lignesCommande.reduce((total,l)=>total+(+l.prix), 0))}</Typography>
                        <Typography sx={{display:"inline-block", width:"75%", fontWeight:"bold"}}>Nombre de lignes</Typography>
                        <Typography sx={{display:"inline-block", width:"25%", textAlign:"right"}}>{lignesCommande.length}</Typography>
                        <LoadingButton disabled={!answersOk} loading={creationEnCours} onClick={handleClickCommande} variant="contained" color="primary" sx={{float:"right"}}>{id===undefined?"Créer":"Modifier"} la commande</LoadingButton>
                    </Box>
                </Box>
            </Box>
            <PopupCreationClient open={openCreationClient} callback={callbackCreationClient} />
            <PopupProduit open={openAjoutProduit} data={{produit:selectedProduct, ligne:ligneData}} callback={callbackPopupProduit} commande={commande}/>
        </>
    )
}


function PopupCreationClient({open, callback}){
    const [reponsesClient, setReponsesClient] = useState({nom:undefined, prenom:undefined, tel1:undefined});
    const [answersOk, setAnswersOk] = useState(false)

    const stylePopup = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "60%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const styleInputLabel={
        marginY:"auto", 
        display:"inline-block", 
        mr:2
    }
    const styleBoxInput={
        display:"flex", 
        justifyContent:"right", 
        mb:2
    }

    /*useEffect(()=>{
        console.log("coucou")
    },[open])

    useEffect(()=>{
        if(!client || !client.identifiant){
            setClientsPotentiels([])
            return
        }
        setProcessing(true)
        API("GET","client",{},{identifiant:client.identifiant})
        .then(res=>{
            setClientsPotentiels(res.json)
            setProcessing(false)
        })
    },[client])*/


    const handleChange = (key, value)=>{
        setReponsesClient(currVal => ({...currVal, [key]:value}))
    }

    useEffect(() => {
        setAnswersOk(Object.values(reponsesClient).filter(a=>a==undefined).length===0)
    }, [reponsesClient]);

    const handleSend = ()=>{
        API("POST","client",reponsesClient)
        .then(res=>{
            if(res.status == 201){
                callback({...reponsesClient, id:res.json.insertId})
            }else{
                console.error("POST client non réussi",res)
            }
        })
    }

    return(
        <Modal open={open||false} onClose={()=>callback(false)}>
            <Box sx={stylePopup}>
                <Typography variant="h5" sx={{textAlign:"center", mb:2}}>Ajout d'un client</Typography>
                <Box sx={{display:"flex"}}>
                    <Box sx={{width:"50%"}}>
                        <Box sx={styleBoxInput}>
                            <Typography sx={styleInputLabel}>Prénom *</Typography>
                            <Input required={true} onChange={(v)=>handleChange("prenom",v)} style={{width:250}} InputProps={{sx:{py:1}}}/>
                        </Box>
                        <Box sx={styleBoxInput}>
                            <Typography sx={styleInputLabel}>Nom *</Typography>
                            <Input required={true} onChange={(v)=>handleChange("nom",v)} style={{width:250}} InputProps={{sx:{py:1}}}/>
                        </Box>
                        <Box sx={styleBoxInput}>
                            <Typography sx={styleInputLabel}>Téléphone *</Typography>
                            <Input required={true} onChange={(v)=>handleChange("tel1",v)} style={{width:250}} InputProps={{sx:{py:1}}}/>
                        </Box>
                        <Box sx={styleBoxInput}>
                            <Typography sx={styleInputLabel}>Téléphone 2</Typography>
                            <Input required={false} onChange={(v)=>handleChange("tel2",v)} style={{width:250}} InputProps={{sx:{py:1}}}/>
                        </Box>
                        <Button onClick={()=>callback(false)} variant="contained" color="secondary" style={{display: "block", marginLeft: "auto", marginRight: 20, marginTop: 15}}>Annuler</Button>
                    </Box>
                    <Box sx={{width:"50%"}}>
                        <Box sx={styleBoxInput}>
                            <Typography sx={styleInputLabel}>Email</Typography>
                            <Input required={false} onChange={(v)=>handleChange("email",v)} style={{width:250}} InputProps={{sx:{py:1}}}/>
                        </Box>
                        {/*<Box sx={styleBoxInput}>
                            <Typography sx={styleInputLabel}>Type</Typography>
                            <Dropdown options={["Standard","Salarié","Privilège"]} defaultValue="Standard" onChange={(v)=>handleChange("type",v)} style={{width:250}}  inputProps={{sx:{"& > .MuiSelect-select":{py:"8px !important"}}}}/>
                        </Box>
                        <Box sx={styleBoxInput}>
                            <Typography sx={styleInputLabel}>Taux prix</Typography>
                            <Input required={false} defaultValue={1} onChange={(v)=>handleChange("taux_prix",v)} style={{width:250}} InputProps={{sx:{py:1}}}/>
                        </Box>*/}
                        <Box sx={styleBoxInput}>
                            <Typography sx={styleInputLabel}>Adresse</Typography>
                            <Input required={false} onChange={(v)=>handleChange("adresse",v)} style={{width:250}} InputProps={{sx:{py:1}}}/>
                        </Box>
                        <Box sx={styleBoxInput}>
                            <Typography sx={styleInputLabel}>Code postal</Typography>
                            <Input required={false} onChange={(v)=>handleChange("code_postal",v)} style={{width:250}} InputProps={{sx:{py:1}}}/>
                        </Box>
                        <Box sx={styleBoxInput}>
                            <Typography sx={styleInputLabel}>Ville</Typography>
                            <Input required={false} onChange={(v)=>handleChange("ville",v)} style={{width:250}} InputProps={{sx:{py:1}}}/>
                        </Box>
                        <Button disabled={!answersOk} onClick={handleSend} variant="contained" color="primary" style={{display: "block", marginLeft: 20, marginRight: "auto", marginTop: 15}}>Créer</Button>
                    </Box>
                </Box>
            </Box>
      </Modal>
    )
}


function PopupProduit({open, data, callback, commande}){
    const [reponsesLigneCommande, setReponseLigneCommande] = useState({Produit_id:undefined, quantite:undefined});
    const [answersOk, setAnswersOk] = useState(false)

    const [prochainJourReception, setProchainJourReception] = useState(undefined);
    const [stock, setStock] = useState(undefined);
    const [commandees, setCommandees] = useState({});

    const [disponibilite, setDisponibilite] = useState("");

    

    const stylePopup = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "60%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const styleInputLabel={
        marginY:"auto", 
        display:"inline-block", 
        mr:2,
        minWidth:"100px",
        textAlign:"right"
    }
    const styleBoxInput={
        display:"flex", 
        justifyContent:"center", 
        mb:2
    }

    useEffect(() => {
        if(!open) return

        API("GET","Fournisseur_Condition",{},{Produit_id:data.produit.id, type:["Standard"]})
        .then(res=>{
            if(res.json?.length>0){
                setProchainJourReception(
                    getJoursCommandeEtReception(res.json[0].jour_commande, res.json[0].jour_reception).prochain_jour_reception
                )
            }
        })

        API("GET", "mouvement_stock/stock",{},{Produit_id:[data.produit.id]})
        .then(res=>{
            setStock(+res.json[0].stock)
        })

        API("GET", "ligne_commande/quantite", {}, {Produit_id:[data.produit.id], etat:["A faire"], dateMin:dayjs().format("YYYY-MM-DD") , dateMax:dayjs().add(14,'day').format("YYYY-MM-DD")})
        .then(res=>{
            let tmp = {}
            res.json.forEach((lc, i)=>{
                tmp[lc.date_livraison] = +lc.quantite
            })
            setCommandees(tmp)
        })
    }, [open]);

    useEffect(() => {
        if(prochainJourReception===undefined) return
        if(stock===undefined) return
        if(Object.keys(commandees).length === 0) return

        setDisponibilite(currVal => {
            if(dayjs(commande.date_livraison)>=dayjs(prochainJourReception)){
                return "Illimité"
            }else{
                return stock-Object.entries(commandees).filter(([date, qte])=>dayjs(date)<dayjs(prochainJourReception)).reduce((acc,[date,qte])=>acc+qte,0)
            }
        })
    }, [prochainJourReception, stock, commandees]);
    

    useEffect(() => {
        if(data.ligne !== undefined){
            setReponseLigneCommande(data.ligne)
        }else{
            if(data.produit?.id){
                let temp ={}
                if(data.produit.option_1){
                    temp.option_1=data.produit.option_1?.split("|")[0]
                }
                if(data.produit.option_2){
                    temp.option_2=data.produit.option_2?.split("|")[0]
                }
                setReponseLigneCommande(currVal=>({
                    ...currVal, 
                    ...temp,
                    Produit_id:data.produit.id, 
                    Produit_nom:data.produit.nom, 
                    Produit_unite:data.produit.unite_vente, 
                    Produit_prix:data.produit.prix||0
                }))
            }else{
                setReponseLigneCommande({Produit_id:undefined, quantite:undefined})
            }
        }
    }, [data]);


    const handleChange = (key, value)=>{
        setReponseLigneCommande(currVal => ({...currVal, [key]:value}))
    }

    useEffect(() => {
        setAnswersOk(Object.values(reponsesLigneCommande).filter(a=>a===undefined).length===0)
        console.log("reponsesLigneCommande",reponsesLigneCommande)
    }, [reponsesLigneCommande]);

    return(
        <Modal open={open||false} onClose={()=>callback(false)}>
            <Box sx={stylePopup}>
                <Typography variant="h5" sx={{textAlign:"center", mb:2}}>{data.ligne?"Modification":"Ajout"} d'une ligne de commande</Typography>
                <Box sx={styleBoxInput}>
                    <Typography sx={styleInputLabel}>Produit</Typography>
                    <Input disabled={true} defaultValue={reponsesLigneCommande.Produit_nom} style={{width:250}} otherProps={{size:"small"}}/>
                </Box>
                <Box sx={{...styleBoxInput, mb:0}}>
                    <Typography sx={styleInputLabel}>Quantié *</Typography>
                    <Number required={true} defaultValue={reponsesLigneCommande.quantite || data.ligne?.quantite} onChange={(v)=>handleChange("quantite",v)} decimalScale={3} minimum={0} ornementFin={data.produit.unite_vente} inputProps={{size:"small"}}/>
                </Box>
                <Box sx={{...styleBoxInput, ml:"100px"}}>
                    Disponibilité :  <span style={{fontWeight:"bold", margin:"0 10px"}}>{disponibilite}</span> pour une commande au {dayjs(commande.date_livraison).format("DD-MM-YYYY")}
                </Box>
                {data.produit.option_1 &&
                <Box sx={styleBoxInput}>
                    <Typography sx={styleInputLabel}>Option 1</Typography>
                    <Dropdown options={data.produit.option_1.split("|")} defaultValue={reponsesLigneCommande?.option_1 || data.produit.option_1.split("|")[0]} onChange={(v)=>handleChange("option_1",v)} style={{width:250}} otherProps={{size:"small"}}/>
                </Box>
                }
                {data.produit.option_2 &&
                <Box sx={styleBoxInput}>
                    <Typography sx={styleInputLabel}>Option 2</Typography>
                    <Dropdown options={data.produit.option_2.split("|")} defaultValue={reponsesLigneCommande?.option_2 || data.produit.option_2.split("|")[0]} onChange={(v)=>handleChange("option_2",v)} style={{width:250}} otherProps={{size:"small"}}/>
                </Box>
                }
                <Box sx={styleBoxInput}>
                    <Typography sx={styleInputLabel}>Commentaire</Typography>
                    <Input defaultValue={reponsesLigneCommande?.commentaire} onChange={(v)=>handleChange("commentaire",v)} style={{width:250}} otherProps={{size:"small",multiline:true}}/>
                </Box>
                <Box sx={{display:"flex", justifyContent:"space-evenly"}}>
                    {data.ligne && <Button onClick={()=>callback({...reponsesLigneCommande, toDelete:true})} variant="contained" color="secondary">Supprimer</Button>}
                    <Button onClick={()=>callback(false)} variant="outlined" color="primary" >Annuler</Button>
                    <Button disabled={!answersOk} onClick={()=>callback(reponsesLigneCommande)} variant="contained" color="primary" >{data.ligne?"Modifier":"Créer"}</Button>
                </Box>
            </Box>
      </Modal>
    )
}

function BlocLigneCommande({detailLigne, onClick}){

    /*useEffect(() => {
        console.log("detailLigne", detailLigne)
    }, [detailLigne]);*/

    let commun ={
        display:"inline-block",
        fontSize:"18px"

    }
    return(
        <Box sx={{my:"20px"}} onClick={onClick}>
            <Typography sx={{...commun, width:"50%", fontWeight: "bold"}}>{detailLigne.Produit_nom}</Typography>
            <Typography sx={{...commun, width:"25%", textAlign:"center"}}>{detailLigne.quantite} {detailLigne.Produit_unite}</Typography>
            <Typography sx={{...commun, width:"25%", textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(detailLigne.prix)}</Typography>
            <Box sx={{display:"flex", flexWrap:"wrap", width:"50%"}}>
                {detailLigne.option_1 && 
                    <Typography>{detailLigne.option_1}</Typography>
                }
                {detailLigne.option_2 && 
                    <><Typography> - </Typography>
                    <Typography>{detailLigne.option_2}</Typography></>
                }
                {detailLigne.commentaire && 
                    <Typography sx={{width:"100%",overflowWrap: "break-word"}}>{detailLigne.commentaire}</Typography>
                }
            </Box>
        </Box>
    )
}