import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import API from "../services/API";
import Dropdown from "../components/Dropdown";
import Input from "../components/Input";
import { Check, Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Number from "../components/Number";






export default function ReorganisationLocal({}){
    const [locaux, setLocaux] = useState([]);
    const [emplacements, setEmplacements] = useState([]);

    const [selectedLocal, setSelectedLocal] = useState(undefined);
    const [produitsAAfficher, setProduitsAAfficher] = useState({});

    const [saisieValider, setSaisieValider] = useState({});
    const [validationEnCours, setValidationEnCours] = useState(false);

    const [refreshData, setRefreshData] = useState(true);

    useEffect(() => {
        API("GET","local")
        .then(res=>{
            setLocaux(res.json)
            setSelectedLocal(res.json[0].nom)
        })


        API("GET","emplacement")
        .then(res=>setEmplacements(res.json))
    }, []);

    useEffect(() => {
        if(emplacements.length===0) return
        if(selectedLocal===undefined) return 
        setProduitsAAfficher({})

        if(!refreshData) return
        setRefreshData(false)

        let emplacements_local = emplacements.filter(e=>e.Local_nom===selectedLocal).map(e=>{return e.nom})


        API("GET","Mouvement_Stock/Stock", {}, {emplacements:emplacements_local})
        .then(res=>{
            if(res.status === 200){
                let toSave = {}
                res.json.forEach(s=>{
                    if(!(toSave.hasOwnProperty(s.id ))){
                        toSave[s.id]=s
                        toSave[s.id]["stocks"] = {}
                    }
                    toSave[s.id]["stocks"][s.Emplacement_nom] = s.stock
                })
                setProduitsAAfficher(toSave)

                API("GET","Mouvement_stock/Stock_total",{},{produits : Object.keys(toSave)})
                .then(res2=>{
                    if(res2.status === 200){
                        res2.json.forEach(s=>{
                            toSave[s.Produit_id]["stock_total"] = +s.stock
                        })
                        setProduitsAAfficher(toSave)
                    }
                })
            }
        })


    }, [selectedLocal, emplacements, refreshData]);


    const handleChangeSaisieValider = (produit_id, empSrc, quantite, empDest)=>{
        setSaisieValider(currVal=>{
            let temp = {...currVal}
            if(!temp.hasOwnProperty(produit_id)){
                temp[produit_id]={}
            }
            temp[produit_id][empSrc] = [quantite, empDest]
            return temp
        })
    }

    const validationPossible = Object.values(saisieValider).reduce((acc, objet) => {
        if (acc) {
            return true; // Si un point d'interrogation est différent de '-', on renvoie false directement
        }

        return Object.values(objet).reduce((acc2,tab)=> {
            return acc2 || tab[1]!=="-"
        },false)
    
    }, false); // true est la valeur initiale de "resultat"

    const handleValider= ()=>{
        setValidationEnCours(true)
        let mvts = []
        Object.entries(saisieValider).forEach(([produit_id, objet])=>{
            Object.entries(objet).forEach(([empSrc,[quantite, empDest]])=>{
                if(empDest!=="-"){
                    mvts.push({
                        Produit_id : produit_id, 
                        Emplacement_nom : empSrc, 
                        quantite : -1 * quantite,
                        motif : "Réorganisation"
                    })
                    mvts.push({
                        Produit_id : produit_id, 
                        Emplacement_nom : empDest, 
                        quantite : quantite,
                        motif : "Réorganisation"
                    })
                }
            })
        })

        API("POST","mouvement_stock",mvts)
        .then(res=>{
            if(res.status ===201){
                setSaisieValider({})
                setRefreshData(true)
            }
            setValidationEnCours(false)
        })

    }


    /*useEffect(() => {
        console.log(produitsAAfficher)
    }, [produitsAAfficher]);*/

    useEffect(() => {
        console.log(saisieValider)
    }, [saisieValider]);

    return(
        <>
            <Typography variant="h5" sx={{textAlign:'center'}}>Réorganisation du local</Typography>
            <Box sx={{width:"100%", overflowX:"auto", whiteSpace:"nowrap"}}>
                <Box sx={{borderBottom:"2px solid black"}}>
                    <Box sx={{width:"74%",display:"inline-block"}}>
                        <Typography variant="h6" sx={{display:"inline-block", verticalAlign:"middle"}}>Local : </Typography>
                        {locaux.length>0 && <Dropdown options={locaux.map(l=>l.nom)} onChange={(v)=>{setSelectedLocal(v); setRefreshData(true)}} defaultValue={locaux[0].nom} style={{width:150, verticalAlign:"middle"}} otherProps={{size:"small"}}/>}
                    </Box>
                    <Box sx={{width:"25%",display:"inline-block", textAlign:'center', borderLeft:"1px solid black", pb:1}}>
                        <Typography variant="h6" sx={{}}>Nouvel emplacement</Typography>
                    </Box>
                </Box>
                <Box sx={{}}>
                    <Box sx={{width:"75%",display:"inline-block"}}>
                        <Box sx={{width:"25%",display:"inline-block",textAlign:"center",verticalAlign:"middle", fontWeight:"bold"}}>Produit</Box>
                        <Box sx={{width:"25%",display:"inline-block",textAlign:"center",verticalAlign:"middle", fontWeight:"bold", whiteSpace: "normal"}}>Emplacement par défaut</Box>
                        <Box sx={{width:"25%",display:"inline-block",textAlign:"center",verticalAlign:"middle", fontWeight:"bold"}}>Stock total</Box>
                        <Box sx={{width:"25%",display:"inline-block",textAlign:"center",verticalAlign:"middle", fontWeight:"bold"}}>Stock local</Box>
                    </Box>
                </Box>
                {Object.values(produitsAAfficher).map(p=>(
                    <Box key={p.id} sx={{borderBottom:"1px dashed gray"}}>
                        <Box sx={{width:"74%",display:"inline-block",verticalAlign:"middle"}}>
                            <Box sx={{width:"25%",display:"inline-block",verticalAlign:"middle"}}>{p.nom}</Box>
                            <Box sx={{width:"25%",display:"inline-block",textAlign:"center",verticalAlign:"middle"}}>{p.Emplacement_defaut}</Box>
                            <Box sx={{width:"25%",display:"inline-block",textAlign:"center",verticalAlign:"middle"}}>{p.stock_total}</Box>
                            <Box sx={{width:"25%",display:"inline-block",textAlign:"center",verticalAlign:"middle"}}>
                                {Object.entries(p.stocks).map(([emp,stk])=>(
                                    <Box sx={{}}>{+stk} en {emp}</Box>
                                ))}
                            </Box>
                        </Box>
                        <Box sx={{width:"25%",display:"inline-block", textAlign:'center', borderLeft:"1px solid black",verticalAlign:"middle"}}>
                            <Box sx={{m:1}}>
                                {Object.entries(p.stocks).map(([empSrc,quantite])=>(
                                    <Dropdown key={p.id+"-"+empSrc} defaultValue="-" options={["-",...emplacements.filter(e=>e.Local_nom===selectedLocal).map(e=>e.nom)]} disableOptions={[empSrc]} onChange={(v)=>handleChangeSaisieValider(p.id, empSrc, quantite, v)} style={{width:70, display:"flex", margin:"auto"}} otherProps={{size:"small"}}/>
                                ))}
                            </Box>  
                        </Box>
                    </Box>
                ))}
                
                <Box sx={{  }}>
                    <Box sx={{width:"74%",display:"inline-block"}}></Box>
                    <Box sx={{pt : 3, width:"25%",display:"inline-block", textAlign:'center', borderLeft:"1px solid black",verticalAlign:"middle"}}>
                        <LoadingButton disabled={!validationPossible} loading={validationEnCours} variant="contained" onClick={handleValider}><Check/> Valider</LoadingButton>
                    </Box>
                </Box>
            </Box>
        </>
    )
}