import React, { useEffect, useState } from "react";

const BlockingContext = React.createContext([{}, () => {}]);

let initialState = {};

const BlockingProvider = ({children}) => {
    const [isBlocking, setIsBlocking] = useState(false);

  return (
    <BlockingContext.Provider value={[isBlocking, setIsBlocking]}>
      {children}
    </BlockingContext.Provider>
  );
};

export { BlockingContext, BlockingProvider };