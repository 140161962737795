import { useEffect, useState } from "react";
import API from "../services/API";
import { Box, Typography } from "@mui/material";
import Dropdown from "../components/Dropdown";
import CheckboxItem from "../components/CheckboxItem";
import dayjs from "dayjs";
import Number from "../components/Number";
import { LoadingButton } from "@mui/lab";
import {getJoursCommandeEtReception} from "../services/Utils"




export default function CommandeFrn(){
    const [fournisseurs, setFournisseurs] = useState([]);
    const [selectedFournisseur, setSelectedFournisseur] = useState(undefined);
    
    const [selectedTypeFrn, setSelectedTypeFrn] = useState(["Standard"]);

    const [fournisseursConditions, setFournisseursConditions] = useState([]);
    
    const [periodes, setPeriodes] = useState([]);
    const [selectedPeriode, setSelectedPeriode] = useState(localStorage.getItem("selectedPeriode")||"");

    const [commandees, setCommandees] = useState({});
    const [previsionnels, setPrevisionnels] = useState({});
    const [stocks, setStocks] = useState({});
    const [commandeesFRN, setCommandeesFRN] = useState({});

    const [aCommander, setACommander] = useState({});
    const [commandeEnCours, setCommandeEnCours] = useState(false);

    useEffect(() => {
        API("GET","Produit_previsionnel/periodes")
        .then(res=>setPeriodes(res.json))

        API("GET", "Fournisseur")
        .then(res=>setFournisseurs(res.json))


    }, []);


    const handleChangeTypeFrn = (type, value)=>{
        setSelectedTypeFrn(currVal=>{
            let tmp = [...currVal]
            if (value && !tmp.includes(type)){
                tmp.push(type)
            }else if(!value && tmp.includes(type)){
                tmp.splice(tmp.indexOf(type),1)
            }
            return tmp
        })
    }

    useEffect(() => {
        if(selectedFournisseur===undefined) return

        setACommander({})

        API("GET", "Fournisseur_Condition",{},{Fournisseur_id:fournisseurs.find(f=>f.nom===selectedFournisseur)?.id, type:selectedTypeFrn, orderBy:"ordre"})
        .then(res=>{
            let tmp = [...res.json]
            tmp.forEach((fc,i)=>{
                tmp[i] = {...tmp[i], ...getJoursCommandeEtReception(fc.jour_commande, fc.jour_reception)}
            })
            setFournisseursConditions(tmp)
        })
    }, [selectedFournisseur, selectedTypeFrn]);

    const handleChangePeriode = (periode)=>{
        localStorage.setItem("selectedPeriode", periode)
        setSelectedPeriode(periode)
    }

    useEffect(() => {
        if(fournisseursConditions.length===0) return 
        if(selectedPeriode===undefined) return

        const weekDays = ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"]
        
        API("GET", "Produit_Previsionnel", {}, {nom_periode : selectedPeriode, Produit_id:fournisseursConditions.map(fc=>fc.Produit_id)})
        .then(res=>{
            let tmp = {}
            let date
            res.json.forEach((pp, i)=>{
                tmp[pp.Produit_id]={}
                for(let i =0; i<15; i++){
                    date = dayjs().add(i,'day')
                    tmp[pp.Produit_id][date.format('YYYY-MM-DD')]=+pp["quantite_"+weekDays[date.day()]]
                }
            })

            let tmpPrevisionnels = {}
            fournisseursConditions.forEach((fc,i)=>{
                tmpPrevisionnels[fc.Produit_id] = Object.entries(tmp[fc.Produit_id]).filter(([date, q])=>dayjs(date)<=dayjs(fc.prochain_jour_reception)).reduce((acc,[date, q])=>{return acc+q},0)
            })

            setPrevisionnels(tmpPrevisionnels)
        })
    }, [fournisseursConditions, selectedPeriode]);

    useEffect(() => {
        if(fournisseursConditions.length===0) return 

        let tmpLC = {}
        let tmpS = {}
        let tmpLCF = {}
        fournisseursConditions.forEach((f,i)=>{
            tmpLC[f.Produit_id] = {}
            tmpLCF[f.Produit_id] = {}
            for(let i =0; i<15; i++){
                tmpLC[f.Produit_id][dayjs().add(i,'day').format('YYYY-MM-DD')] = 0
                tmpLCF[f.Produit_id][dayjs().add(i,'day').format('YYYY-MM-DD')] = 0
            }

            tmpS[f.Produit_id] = 0

        })

        const Produit_id_list = fournisseursConditions.map(fc=>fc.Produit_id)

        API("GET", "ligne_commande/quantite", {}, {Produit_id:Produit_id_list, etat:["A faire"], dateMin:dayjs().format("YYYY-MM-DD") , dateMax:dayjs().add(14,'day').format("YYYY-MM-DD")})
        .then(res=>{
            res.json.forEach((lc, i)=>{
                tmpLC[lc.Produit_id][lc.date_livraison]=+lc.quantite
            })

            let tmpCommandees = {}
            fournisseursConditions.forEach((fc,i)=>{
                tmpCommandees[fc.Produit_id] = Object.entries(tmpLC[fc.Produit_id]).filter(([date, q])=>dayjs(date)<=dayjs(fc.prochain_jour_reception)).reduce((acc,[date, q])=>{return acc+q},0)
            })

            setCommandees(tmpCommandees)
        })

        API("GET", "mouvement_stock/stock",{},{Produit_id:Produit_id_list})
        .then(res=>{
            res.json.forEach((s,i)=>{
                tmpS[s.id]=+s.stock
            })
            setStocks(tmpS)
        })

        API("GET","Ligne_Commande_Fournisseur",{},{Produit_id:Produit_id_list, resteALivrer:true, dateMax:dayjs().add(14,'day').format("YYYY-MM-DD"), groupBy:"Produit_id, date_reception_estimee"})
        .then(res=>{
            res.json.forEach((lcf,i)=>{
                tmpLCF[lcf.Produit_id][lcf.date_reception_estimee]=+lcf.quantite_restante
            })

            let tmpCommandeesFRN = {}
            fournisseursConditions.forEach((fc,i)=>{
                tmpCommandeesFRN[fc.Produit_id] = Object.entries(tmpLCF[fc.Produit_id]).filter(([date, q])=>dayjs(date)<=dayjs(fc.prochain_jour_reception)).reduce((acc,[date, q])=>{return acc+q},0)
            })
            setCommandeesFRN(tmpCommandeesFRN)
        })

    }, [fournisseursConditions]);

    const handleCommander = ()=>{
        setCommandeEnCours(true)
        let toSend = []
        const Fournisseur_id = fournisseurs.find(f=>f.nom===selectedFournisseur)?.id
        Object.entries(aCommander).forEach(([Produit_id, [qte,date_reception]])=>{
            if(qte===0) return
            toSend.push({Fournisseur_id:Fournisseur_id, Produit_id:Produit_id, quantite_commandee:qte, date_reception_estimee:date_reception})
        })

        API("POST","ligne_commande_fournisseur",toSend)
        .then(res=>{
            setCommandeEnCours(false)
        })
    }

    useEffect(() => {
        console.log(aCommander)
    }, [aCommander]);
    /*useEffect(() => {
        console.log(fournisseursConditions)
    }, [fournisseursConditions]);*/
    

    return(
        <>
            <Typography variant="h5" sx={{textAlign:'center'}}>Commande fournisseur</Typography>

            <Box>
                <Dropdown name="Fournisseur" options={fournisseurs.map(f=>f.nom)} onChange={setSelectedFournisseur} addFirstDisabledOption={true} style={{width:250, mr:6}} otherProps={{size:"small"}}/>
                <CheckboxItem name="Standard" defaultValue={true} onChange={(v)=>handleChangeTypeFrn("Standard",v)} sx={{mr:6}}/>
                <CheckboxItem name="Urgent" defaultValue={false} onChange={(v)=>handleChangeTypeFrn("Urgent",v)}/>

                <Dropdown name="Période" defaultValue={selectedPeriode} options={periodes} onChange={handleChangePeriode} style={{width:150, float:"right"}} otherProps={{size:"small"}} />
            </Box>

            <Box sx={{mt:3, borderBottom:"1px solid black"}}>
                <Box sx={{display:"inline-block", width:"25%"}}>Produit</Box>
                <Box sx={{display:"inline-block", width:"18%", textAlign:"center"}}>Commandes clients<br/>A faire</Box>
                <Box sx={{display:"inline-block", width:"18%", textAlign:"center"}}>Prévisionnel</Box>
                <Box sx={{display:"inline-block", width:"18%", textAlign:"center"}}>Stock</Box>
                <Box sx={{display:"inline-block", width:"21%", textAlign:"center"}}>A commander</Box>
            </Box>

            <Box>
                {fournisseursConditions?.map(fc=>(
                <Box key={fc.id} sx={{my:2}}>
                    <Box sx={{display:"inline-block", width:"25%"}}>{fc.Produit_nom}</Box>
                    <Box sx={{display:"inline-block", width:"18%", textAlign:"center"}}>{commandees[fc.Produit_id]}</Box>
                    <Box sx={{display:"inline-block", width:"18%", textAlign:"center"}}>{previsionnels[fc.Produit_id]}</Box>
                    <Box sx={{display:"inline-block", width:"18%", textAlign:"center"}}>{stocks[fc.Produit_id]}</Box>
                    <Box sx={{display:"inline-block", width:"21%", textAlign:"center"}}>
                        <Number onChange={(v)=>{setACommander(currVal=>({...currVal, [fc.Produit_id]:[v, fc.prochain_jour_reception]}))}} defaultValue={Math.max(0,commandees[fc.Produit_id]+previsionnels[fc.Produit_id]-stocks[fc.Produit_id]-commandeesFRN[fc.Produit_id])} allowNegative={false} inputProps={{size:"small"}} style={{width:70,verticalAlign:"middle"}} />
                    </Box>
                </Box>
                ))}
            </Box>

            <Box>
                <Box sx={{display:"inline-block", width:"79%"}}></Box>
                <Box sx={{display:"inline-block", width:"21%", textAlign:"center"}}>
                    <LoadingButton onClick={handleCommander} loading={commandeEnCours} disabled={Object.values(aCommander).reduce((acc,[q,date])=>acc+q,0)===0} variant="contained">Commander</LoadingButton>
                </Box>
            </Box>
        </>
    )

}
