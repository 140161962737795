import dayjs from "dayjs";
import { useContext } from 'react';
import { UserContext } from "../services/UserContext"
import { Navigate, useParams } from 'react-router-dom';



function getJoursCommandeEtReception(joursCommande, joursReception){
    joursCommande = joursCommande.split(",")
    const joursDeLaSemaine = ['D', 'L', 'M', 'Me', 'J', 'V', 'S'];
    let prochainJourCommande = null;
    let prochainJourIndex = null;
    for (let [i, jour] of joursCommande.entries()) {
        const dayOf = joursDeLaSemaine.indexOf(jour);
        if (dayOf !== -1) {
            if (prochainJourCommande === null || diffFromXToToday(dayOf) < diffFromXToToday(prochainJourCommande)) {
                prochainJourCommande = dayOf;
                prochainJourIndex = i
            }
        }
    }

    const prochainJourReception = joursDeLaSemaine.indexOf(joursReception.split(",")[prochainJourIndex])
    const joursRestantsCommande = diffFromXToToday(prochainJourCommande)
    let joursRestantsReception = diffFromXToToday(prochainJourReception)
    if(joursRestantsReception<joursRestantsCommande){
        joursRestantsReception +=7
    }

    return {
        "prochain_jour_commande" : dayjs().add(joursRestantsCommande,"day").format("YYYY-MM-DD"), 
        "prochain_jour_reception" : dayjs().add(joursRestantsReception,"day").format("YYYY-MM-DD")
    }
}

function diffFromXToToday(day){
    return (7+ day - dayjs().day())%7
}


function RequireRole({roles, children, defaultRedirect="/"}) {
    const [userContext, setUserContext] = useContext(UserContext)
    let { id } = useParams()

    if(roles.includes(userContext.role) || (roles.includes("me") && id==userContext.id)){
        return children;
    }else{
        return <Navigate to={defaultRedirect} />;
    }
}


export {getJoursCommandeEtReception, diffFromXToToday, RequireRole}