import {BrowserRouter as Router, Redirect, Route, Routes, useLocation, Navigate, RouterProvider, createBrowserRouter, createRoutesFromElements} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Accueil from './scenes/Accueil';
import { UserContext, UserProvider } from "./services/UserContext"
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr';
import React, { useCallback, useContext, useEffect } from 'react';
import Login from './scenes/Login';
import API from './services/API';
import { Box, CircularProgress } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: "#27264A"
        },
        secondary:{
            main: "#e10716"
        },
        disabled:{
            main: "#E9E9EC"
        },
        background: {
            main: "#F6F6F9",
        },
        gris:{
            contour:"#CCCCD1"
        }
    },
    typography: {
        fontFamily: "Poppins"
    }
});



export default function App() {

    const router = createBrowserRouter(
        createRoutesFromElements(
            <React.Fragment>
                <Route path="/*" element={<RequireAuth><Accueil/></RequireAuth>}/>
                <Route path="login" element={<Login/>}/>
            </React.Fragment>
        )
      );
  return (
    <ThemeProvider theme={theme}>
        <UserProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                <RouterProvider router={router} />
            </LocalizationProvider>
        </UserProvider>
    </ThemeProvider>
  );
}

function RequireAuth({ children }) {
    const [userContext, setUserContext] = useContext(UserContext)
    let location = useLocation();
    const isLocalhost = window.location.href.includes("localhost")

    const verifyUser = useCallback(() => {
        if(!isLocalhost){
            API("POST","utilisateur/refreshToken",{},{},"application/json",{credentials:"include"})
            .then(res=>{
                if (res.ok) {
                    setUserContext(currVal => ({ ...currVal, token: res.json.token }))
                } else {
                    setUserContext(currVal => ({ ...currVal, token: null }))
                }
                // call refreshToken every 5 minutes to renew the authentication token.
                setTimeout(verifyUser, 5 * 60 * 1000)
            })
        }
    }, [setUserContext])
    
    useEffect(() => {
        verifyUser()
    }, [verifyUser])


    /** Sync logout across tabs **/
    const syncLogout = useCallback(event => {
        if (event.key === "logout") {
            // If using react-router-dom, you may call history.push("/")
            window.location.reload()
        }
    }, [])
    useEffect(() => {
        window.addEventListener("storage", syncLogout)
        return () => {
            window.removeEventListener("storage", syncLogout)
        }
    }, [syncLogout])




  
    if(userContext.token){
        return children;
    }else if (userContext.token===null || isLocalhost) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }else{
        return(
            <Box sx={{ display: 'flex' }}>
                <CircularProgress sx={{display:"block", mx:"auto", my:4}} />
            </Box>
        )
    }
}



/*TODO

- reception fournisseur : modifier date_reception_estime si quantité restante
- droits accès
- message d'erreur avec snackbar

*/